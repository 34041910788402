import { useQuery } from "react-query";
import { getSurveys } from "../../../api/sip/limesurvey";
import Select from "react-select";
import { useEffect, useMemo, useRef, useState } from "react";
import { useBoundNotificationFcm } from "../../../store/NotificationFcm/useBoundNotificationFcm";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { getProjects } from "../../../api/sio/project";


export const SelectPanelistSurvey = () => {

    const intervalProgress= useRef(1000);
    const [rowSelect, setRowSelect] = useState([]);
    const [totalPanelist, setTotalPanelist] = useState(0);
    const { data:responseSurvey, isLoading } = useQuery(['surveys'], getProjects);
    const { setter,batchProgress,progressLoadPanelists,notification,searchSegmentationSurvey, loadings, projectSelect,confirmLoadPanelistSurvey } = useBoundNotificationFcm(state => state);
    const [ surveyId, setSurveyId ] = useState({value: projectSelect.id, label: projectSelect.name});
    const uploadPanelists = (e) => {
        confirmLoadPanelistSurvey(rowSelect);
    }

    useEffect( () => {
        if(["progress"].includes(notification.status) && parseInt(notification.step) == 3 && notification.totalPanelists == 0){ console.log("@accede return"); return; }
        if(notification.batchId != undefined){
            if(notification.status != 'progress' && batchProgress == undefined){ return; }
            if(intervalProgress.current != undefined){
                clearInterval(intervalProgress.current);
            }
            intervalProgress.current = setInterval(() => {
                console.log('@batchProgress', batchProgress);
                if(batchProgress?.progress == 100){
                    clearInterval(intervalProgress.current);
                    setter({batchProgress: undefined});
                    return;

                }
                if (batchProgress?.progress >= 0 && batchProgress?.progress <= 100) {
                    progressLoadPanelists();
                }
            }, 2000);
        }
        return () => clearInterval(intervalProgress.current);
    },[notification.batchId, batchProgress?.progress]);

    useEffect( () => {
        if(notification.length == 0){ return; }
        if(parseInt(notification.step) ===3 && notification.type == "survey" && notification.status == "progress"){
            let _segmentation = JSON.parse(notification.segmentation);
            setRowSelect(_segmentation.map( s => s.id.toString()));
            let _total = 0;
            _segmentation.forEach( _s => {
                projectSelect?.segmentations?.forEach( _p => {
                    if(_s.id.toString() === _p.id.toString()){
                        _total = _total + parseInt(_p.totalPanelists);
                        }
                });
            });
            setTotalPanelist(_total);
        }
    },[]);
    return (
        <div>
            <div className="col-md-6">
                {
                    (!isLoading) ? (
                        <>
                            <label htmlFor="" className="form-label">Selecciona encuesta</label>
                            <Select
                                isClearable
                                className="basic-select"
                                value={  surveyId.value ? surveyId :  null }
                                placeholder='Seleccionar'
                                options= { responseSurvey.status == 200 ? responseSurvey.data.filter(x => x.status === "actived").map( x => ({ value: x.id, label: x.name })) : []}
                                onChange = { (e) => {
                                    setRowSelect([]);
                                    setTotalPanelist(0);
                                    setSurveyId(e);
                                    searchSegmentationSurvey(e.value);
                                }}
                            />
                        </>
                    ) : ( <div className="text text-success">Cargando encuestas</div>)
                }
            </div>
            <div>
                {
                    loadings.segmentations ? ( <div><label htmlFor="">Estamos buscando información...</label></div>) : (
                        !Object.keys(projectSelect).length ? ( <div><label htmlFor="">Aún no has seleccionado un proyecto</label></div>) :(
                            <>
                            <table className="table mt-5">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Seleccionar</th>
                                        <th>Nombre</th>
                                        <th>Total panelistas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        projectSelect.segmentations?.map( (segmentation, kSegmentation) => (
                                            <>
                                            <tr key={kSegmentation} className={rowSelect.includes(segmentation.id.toString()) ? "table-primary" : ""}>
                                                <td>{ segmentation.id.toString() }</td>
                                                <td>
                                                    <input checked={rowSelect.includes(segmentation.id.toString()) ? "checked" : "" } type="checkbox" value={segmentation.id}  data-total={segmentation.totalPanelists} onChange={ (e) => {
                                                        if(!e.target.checked){
                                                            setTotalPanelist(parseInt(totalPanelist) - parseInt(e.target.dataset.total));
                                                            const _r = rowSelect.filter(x => x != e.target.value);
                                                            setRowSelect(_r);
                                                        }else{
                                                            setTotalPanelist(parseInt(totalPanelist) + parseInt(e.target.dataset.total));
                                                            setRowSelect([...rowSelect, e.target.value])
                                                        }
                                                    }} />
                                                </td>
                                                <td>{segmentation.name}</td>
                                                <td>{segmentation.totalPanelists}</td>
                                            </tr>
                                            </>
                                        ))
                                    }
                                </tbody>
                            </table>
                            </>

                        )
                    )
                }
            </div>
            {
                rowSelect.length > 0 && (
                    <>
                        <div style={{ 
                            backgroundColor: '#cfe2ff',
                            width:'fit-content',
                            padding:'5px 10px',
                            borderRadius:'8px',
                        }}>
                            <label htmlFor="">Total panelistas: <strong>{totalPanelist}</strong></label>
                        </div>
                        {/* <div className="col-md-12 mt-3" style={{ display:'flex', justifyContent:'flex-end' , gap:20  }}>
                            <button className='btn btn-primary-oo' onClick={uploadPanelists}>Cargar panelistas</button>
                        </div> */}
                        <div className="col-md-12 mt-3" style={{ display:'flex', justifyContent:'flex-end' , gap:20  }}>
                            {
                                batchProgress?.progress < 100 ? (
                                    <button className='btn btn-primary-oo' disabled>{ `${ batchProgress.progress }%` }</button>
                                ):(
                                    ( [2,3].includes(parseInt(notification.step))) && (
                                        <button className='btn btn-primary-oo' onClick={ uploadPanelists }>Cargar panelistas</button>
                                    )
                                )
                            }
                        </div>
                    </>
                )
            }
        </div>
    )
}