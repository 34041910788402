import React, { useEffect } from 'react';
import { useBoundNotificationFcm } from '../../../store/NotificationFcm/useBoundNotificationFcm';
import { capitalizarText } from '../../../helper/utils';

export const ConfirmNotificationData = () => {

    const { notification, panelists, fetchSendNotification, loading, projectSelect,checkProjectNotification } = useBoundNotificationFcm( state => state);

    const handleSendNotification = () => {
        fetchSendNotification();
    }

    useEffect( () => {
        if(notification.type === "survey" && notification.status === "progress" && notification.step === "3" && Object.keys(projectSelect).length == 0){
            let projectId = JSON.parse(notification.segmentation)[0];
            checkProjectNotification(notification.id, projectId.project_id);
        }
    },[]);

    return (
        <div className="card">
            <div className="card-body">
                <table className="table table-bordered">
                    <thead >
                        <tr className=""  >
                            <th colSpan={2} style={{ backgroundColor: 'var(--bs-gray-200)' }}>Detalle notificación</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Nombre Campaña</th>
                            <td>{ notification.name } </td>
                        </tr>
                        <tr>
                            <th>Titulo notificación</th>
                            <td>{ notification.title } </td>
                        </tr>
                        <tr>
                            <th>Descripcion notificación</th>
                            <td>{ notification.description } </td>
                        </tr>
                        {
                            notification.isActionButton != 'disabled' && ( 
                                <>
                                <tr>
                                    <th>Titulo del boton</th>
                                    <td>{ notification.titleButton }</td>
                                </tr>
                                <tr>
                                    <th>Link del boton</th>
                                    <td>{ notification.linkButton }</td>
                                </tr>
                                </>
                            )
                        }
                    </tbody>
                    {
                        notification.type === "campaign" && (
                            <BodyCampaign panelists={ panelists } notification={ notification } />
                        )
                    }
                    {
                        notification.type === "survey" && (
                            <BodySurvey notification = { notification } />
                        )
                    }

                </table>
                <div className="col-md-12 mt-3" style={{ display:'flex', justifyContent:'flex-end' , gap:20  }}>
                    {
                        loading ? (
                            <button className="btn btn-primary-oo" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                Enviando notificaciones...
                            </button>
                        ) : (
                            <button className='btn btn-primary-oo' onClick={ handleSendNotification }>Publicar notificaciones</button>
                        )
                    }
                </div>
            </div>

        </div>
    )
}

const BodySurvey = ({notification}) => {
    return (
        <>
            <thead >
                <tr className="">
                    <th colSpan={2} style={{ backgroundColor: 'var(--bs-gray-200)' }}>Detalle segmentación</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Segmentos</th>
                    <td >
                        <ul>
                            {
                                JSON.parse(notification.segmentation).map(x => <li key={x.id}>{ x.name }</li>)
                            }
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th>Total de base</th>
                    <td>
                        <span style={{
                            backgroundColor:'var(--bs-gray-200)',
                            color:'black',
                            padding:'3px 8px',
                            borderRadius:'10px',
                            fontWeight:'bold',
                            fontSize:'11px'
                        }}>
                        { notification.totalPanelists }
                    </span></td>
                </tr>
            </tbody>
        </>
    )
}
const BodyCampaign = ({panelists, notification}) => {
    return (
        <>
            <thead >
                <tr className="">
                    <th colSpan={2} style={{ backgroundColor: 'var(--bs-gray-200)' }}>Detalle segmentación</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Sexo</th>
                    <td >
                        <div style={{
                            display:'flex',
                            flexWrap:'wrap',
                            gap:'10px'
                        }}>
                            { panelists.gender.selected?.map( g => <span key={g.label} style={{
                            backgroundColor:'var(--bs-gray-200)',
                            color:'black',
                            padding:'3px 8px',
                            borderRadius:'10px',
                            fontWeight:'bold',
                            fontSize:'11px'
                        }}>{g.label}</span>) }
                        </div>
                        </td>
                </tr>
                <tr>
                    <th>{capitalizarText(panelists.tagGeo?.label || '')}</th>
                    <td >
                        <div style={{
                            display:'flex',
                            flexWrap:'wrap',
                            gap:'10px'
                        }}>
                            { panelists.geographies?.map( g => <span key={g.label} style={{
                            backgroundColor:'var(--bs-gray-200)',
                            color:'black',
                            padding:'3px 8px',
                            borderRadius:'10px',
                            fontWeight:'bold',
                            fontSize:'11px'
                        }}>{g.label}</span>) }
                        </div>
                        </td>
                </tr>
                <tr>
                    <th>{capitalizarText(panelists.typeNse?.label || '')}</th>
                    <td >
                        <div style={{
                            display:'flex',
                            flexWrap:'wrap',
                            gap:'10px'
                        }}>
                            { panelists.nse?.map( g => <span key={g.label} style={{
                            backgroundColor:'var(--bs-gray-200)',
                            color:'black',
                            padding:'3px 8px',
                            borderRadius:'10px',
                            fontWeight:'bold',
                            fontSize:'11px'
                        }}>{g.label}</span>) }
                        </div>
                        </td>
                </tr>
                <tr>
                    <th>Edades</th>
                    <td > 
                        <div style={{
                            display:'flex',
                            flexWrap:'wrap',
                            gap:'10px'
                        }}>
                        <span style={{
                            backgroundColor:'var(--bs-gray-200)',
                            color:'black',
                            padding:'3px 8px',
                            borderRadius:'10px',
                            fontWeight:'bold',
                            fontSize:'11px'
                        }}>min: {panelists.ages.min}</span>
                        <span style={{
                            backgroundColor:'var(--bs-gray-200)',
                            color:'black',
                            padding:'3px 8px',
                            borderRadius:'10px',
                            fontWeight:'bold',
                            fontSize:'11px'
                        }}>max: {panelists.ages.max}</span>
                        </div>
                        </td>
                </tr>
                <tr>
                    <th>Total de base</th>
                    <td>
                        <span style={{
                            backgroundColor:'var(--bs-gray-200)',
                            color:'black',
                            padding:'3px 8px',
                            borderRadius:'10px',
                            fontWeight:'bold',
                            fontSize:'11px'
                        }}>
                            { notification.totalPanelists }
                        </span></td>
                </tr>
            </tbody>
        </>
    )
}