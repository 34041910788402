import { create } from "zustand";
import { getTrackerInformation } from "../../api/sio/project"

export const useProjectStore = create((set,get) => {
    return {
        errors: {},
        loading:false,
        trackerInformation: [],
        getTrackerInformation: async (id) => {
            set({loading:true});
            try {
                const response = await getTrackerInformation(id);
                let _d = response.status == 204  ? [] : response.data;
                set({loading:false, trackerInformation: _d });
            } catch (error) {
                set({trackerInformation:[], loading:false});
            }
        },
        setter: (setter) => set({...setter})
    }
}) 