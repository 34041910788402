import { axiosApi } from "../axiosApi";


export const getNotificationFcm = async (id) => {
    try{
        const response = await axiosApi.get(`${import.meta.env.VITE_REACT_APP_URL}/notificaciones/fcm/mostrar/${id}`);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const saveNotificationFcm = async (notification) => {
    try{
        const response = await axiosApi.put(`${import.meta.env.VITE_REACT_APP_URL}/notificaciones/fcm/guardar`, notification);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const  searchPanelists = async (panelists) => {
    try{
        const response = await axiosApi.put(`${import.meta.env.VITE_REACT_APP_URL}/notificaciones/fcm/buscar/panelistas`, panelists);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const confirmLoadPanelists = async (notification) => {
    try{
        const response = await axiosApi.post(`${import.meta.env.VITE_REACT_APP_URL}/notificaciones/fcm/confirmar`, notification);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const confirmLoadPanelistsSurvey = async (notification) => {
    try{
        const response = await axiosApi.post(`${import.meta.env.VITE_REACT_APP_URL}/notificaciones/fcm/confirmar/panelistas/encuesta`, notification);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const progressLoadPanelists = async (notification) => {
    const { batchId } = notification;
    try{
        const response = await axiosApi.get(`${import.meta.env.VITE_REACT_APP_URL}/notificaciones/fcm/progress/${batchId}`);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

// obtener notificaciones 

export const fetchNotifications = async () => {
    try {
        const response = await axiosApi.get(`${import.meta.env.VITE_REACT_APP_URL}/notificaciones/fcm`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
}

// enviar las notificaciones 

export const fetchSendNotifications = async (notification) => {
    try {
        const response = await axiosApi.put(`${import.meta.env.VITE_REACT_APP_URL}/notificaciones/fcm/send`, notification);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
}

// obtener panelistas de una encuesta para notificaciones 

export const fetchSegmentationsSurveyNotifications = async (notificationId, refenceSurvey_id) => {
    try {
        const response = await axiosApi.get(`${import.meta.env.VITE_REACT_APP_URL}/notificaciones/fcm/buscar/${notificationId}/panelistas/survey/${refenceSurvey_id}`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
}

// validar la información de los panelistas 
export const checkProjectNotification = async (notificationId, projectId) => {
    try {
        const response = await axiosApi.get(`${import.meta.env.VITE_REACT_APP_URL}/notificaciones/fcm/check/${notificationId}/projecto/${projectId}`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
}