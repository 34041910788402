import { useCallback, useState } from "react";
import { endOfToday, format, startOfDay, startOfMonth, subMonths } from "date-fns";
import { useExportPanelists } from "../../../../store/PanelistsInsight/usePanelistsStore";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

export const DateTimeFormExportModal = ({ open, setOpen, exportType }) => {

    const [dateFilter, setDateFilter] = useState({
        from: exportType === 'potential'
            ? startOfDay(subMonths(new Date(), 12))
            : startOfMonth(new Date()),
        to: endOfToday()
    });

    const {
        exportStatus,
        resetExportStatus,
        fetchInitExport,
    } = useExportPanelists((state) => state);

    const handleCloseDialog = () => {
        setOpen(!open)
        resetExportStatus(exportType)
    }
    const handleChangeDateTimePicker = (event, rangeType) => {
        setDateFilter({
            ...dateFilter,
            [rangeType]: event
        })
    }
    //iniciar la exportación según exportType correspondiente
    const handleInitExport = useCallback(async (type) => {
        const _filter = {
            dateRange: {
                from: format(dateFilter.from, 'dd-MM-yyyy HH:mm'),
                to: format(dateFilter.to, 'dd-MM-yyyy HH:mm')
            },
        }
        const { status } = await fetchInitExport(_filter, type);
        if (status == 200) {
            setOpen(!open)
        }
    }, [dateFilter]);

    //mostrar errores de datetime inputs según exportStatus correspondiente (campaign o ranking)
    const getErrorHelperText = (exportStatus, rangeType) => {
        const { campaign, ranking, potential } = exportStatus;
        // Verificar si hay errores
        const isCampaignError = campaign.isError && Object.keys(campaign.errors).length > 0;
        const isRankingError = ranking.isError && Object.keys(ranking.errors).length > 0;
        const isPotentialBaseError = potential.isError && Object.keys(potential.errors).length > 0;
        // Si hay errores se retorna el mensaje según corresponda (from o to)
        if (isCampaignError) {
            return campaign.errors[`dateRange.${rangeType}`]?.map((err, i) => <span key={i}>{err}</span>);
        }
        if (isRankingError) {
            return ranking.errors[`dateRange.${rangeType}`]?.map((err, i) => <span key={i}>{err}</span>);
        }
        if (isPotentialBaseError) {
            return potential.errors[`dateRange.${rangeType}`]?.map((err, i) => <span key={i}>{err}</span>);
        }
        // Si no hay errores, retorna string vacío
        return '';
    };

    return (
        <Dialog
            key={exportType}
            open={open}
            onClose={() => setOpen(!open)}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    handleInitExport(exportType);
                },
            }}
        >
            <DialogTitle>Seleccionar rango</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: '30px' }}>
                    {`Selecciona el rango de fechas${exportType === 'potential'
                            ? ' de última interacción para buscar la base potencial'
                            : ' para buscar los datos'
                        }`
                    }
                </DialogContentText>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <DateTimePicker
                        key={'datePicker-from'}
                        label="Desde"
                        onChange={(e) => handleChangeDateTimePicker(e, 'from')}
                        value={dateFilter.from}
                        renderInput={(params) =>
                        (<TextField
                            {...params}
                            size="small"
                            error={exportStatus.campaign.isError || exportStatus.ranking.isError || exportStatus.potential.isError}
                            helperText={getErrorHelperText(exportStatus, 'from')}
                        />)
                        }
                        inputFormat="dd/MM/yyyy HH:mm"
                    />
                    <DateTimePicker
                        key={'datePicker-to'}
                        label="Hasta"
                        onChange={(e) => handleChangeDateTimePicker(e, 'to')}
                        value={dateFilter.to}
                        renderInput={(params) =>
                        (<TextField
                            {...params}
                            size="small"
                            error={exportStatus.campaign.isError || exportStatus.ranking.isError || exportStatus.potential.isError}
                            helperText={getErrorHelperText(exportStatus, 'to')}
                        />)
                        }
                        inputFormat="dd/MM/yyyy HH:mm"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseDialog()}>Cancelar</Button>
                <Button
                    type="submit"
                    sx={{
                        bgcolor: "#46166c",
                        '&:hover': {
                            backgroundColor: '#46166c',
                            opacity: 0.9
                        }
                    }}
                    variant="contained"
                >
                    Exportar
                </Button>
            </DialogActions>
        </Dialog>
    );
}