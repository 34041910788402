import React, { useEffect, useMemo, useState } from 'react';
import { VarInput } from '../VarInput';
import Select from 'react-select';
import { useBoundNotificationFcm } from '../../../store/NotificationFcm/useBoundNotificationFcm';
import { v4 as uuidv4 } from "uuid"


export const FcmCreateNotification = () => {

    const typesNotification = useMemo( () => [{
            type: 'survey',
            label: 'Encuestas',
            value: 'survey',
            image: 'https://avatar.iran.liara.run/public'
        },{
            type: 'campaign',
            label: 'Campaña',
            value: 'campaign',
            image: 'https://avatar.iran.liara.run/public'
        }
    ],[]);
    const [typeNotificationSelect, setTypeNotificationSelect] = useState({
        type: 'campaign',
        label: 'Campaña',
        value: 'campaign',
        image: 'https://avatar.iran.liara.run/public'
    });
    const { notification,setterInput, saveNotificationFcm, errors } = useBoundNotificationFcm(state => state);

    const { name,title,description,isActionButton,titleButton,linkButton } = notification;
    const { notification:nErrors } = errors;
    const { errors:eNotification } = nErrors;
    const handleChange = ({ name, value, selected }) => {
        setterInput(name, value + selected);
    }

    const handleSaveNotification = (e) => {
        e.preventDefault();
        saveNotificationFcm();
    }

    useEffect( () => {
        setTypeNotificationSelect(typesNotification.filter(x => x.value === notification.type)[0]);
    },[]);
    return (
        <div className="row">
        <div className="col-md-8">
            <form onSubmit={ handleSaveNotification }>
                <div className="col-md-12 mb-3">
                    <div style={{ display:'flex', alignItems:'center' }}>
                        <label htmlFor="forTitle" className="form-label"><strong>Nombre de la campaña</strong></label>
                        <VarInput name='name' textInput={ name } handleChange={ handleChange } />
                    </div>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="titleId" 
                        placeholder="Titulo de la notificación" 
                        name="name" 
                        value={ name ?? '' } 
                        onChange={ ({ target }) => {
                            const{ value, name:nameInput, type } = target;
                            setterInput(nameInput, value);
                        }  }/>
                        {
                            eNotification?.name && ( 
                                <label className='mt-2 text text-danger'>{ eNotification?.name[0] } </label>
                            )
                        }
                </div>
                <div className="col-md-12 mb-3">
                    <div style={{ display:'flex', alignItems:'center' }}>
                        <label htmlFor="forTitle" className="form-label"><strong>Titulo</strong></label>
                        <VarInput name='title' textInput={ title } handleChange={ handleChange } />
                    </div>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="titleId" 
                        placeholder="Titulo de la notificación" 
                        name="title" 
                        value={ title ?? '' } 
                        onChange={ ({ target }) => {
                            const{ value, name, type } = target;
                            setterInput(name, value);
                        }  }/>
                        {
                            eNotification?.title && ( 
                                <label className='mt-2 text text-danger'>{ eNotification?.title[0] } </label>
                            )
                        }
                </div>
                <div className="col-md-12 mb-3">
                    <div style={{ display:'flex', alignItems:'center' }}>
                        <label htmlFor="forTitle" className="form-label"><strong>Descripción</strong></label>
                        <VarInput name='description' textInput={ description } handleChange={ handleChange } />
                    </div>
                    <textarea 
                        className="form-control" 
                        id="descriptionId" 
                        rows="7" 
                        name='description'
                        value={ description ?? '' }
                        onChange={ ({ target }) => {
                            const{ value, name, type } = target;
                            setterInput(name, value);
                        }} 
                        style={{ resize:'none' }}>
                    </textarea>
                    {
                        eNotification?.description && ( 
                            <label className='mt-2 text text-danger'>{ eNotification?.description[0] } </label>
                        )
                    }
                </div>
                <div className="col-md-12 mb-3">
                    <div style={{ display:'flex', alignItems:'center' }}>
                        <label htmlFor="forTitle" className="form-label"><strong>Tipo de notificación</strong></label>
                    </div>
                    <Select
                        key={9999}
                        className="basic-select"
                        options={ typesNotification }
                        defaultValue={ typeNotificationSelect }
                        value= { typeNotificationSelect }
                        onChange  = { (e) => {
                            console.log('@e', e);
                            setTypeNotificationSelect(e);
                            setterInput('isActionButton', '');
                            setterInput('titleButton', '');
                            setterInput('linkButton', '');
                            setterInput('type', e.value);
                            if(e.value === 'survey'){
                                setterInput('isActionButton',true);
                            }else{
                                setterInput('isActionButton', false);
                                setterInput('titleButton', '');
                                setterInput('linkButton', '');
                            }
                        }}
                    />
                </div>
                {
                    typeNotificationSelect.value  === 'campaign' && (
                        <div className="form-check form-switch col-md-12 mb-3">
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                name='isActionButton'
                                role="switch" 
                                id="isActionButtonId"
                                checked={ isActionButton }
                                onChange={ ({ target }) => {
                                    const{ value, name, type, checked } = target;
                                    console.log(value, name,type, checked)
                                    setterInput(name, type === 'checkbox' ? checked  : value);
                                    setterInput('titleButton', '');
                                    setterInput('linkButton', '');
                                }  }/>
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">¿La notificación debe contener un boton?</label>
                        </div>
                            
                    )
                }

                {
                    (isActionButton)  && ( 
                        <>
                        <div className="col-md-12 mb-3">
                            <label htmlFor="forTitle" className="form-label"><strong>Titulo del boton</strong></label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="titleButtonId" 
                                placeholder="Titulo del boton" 
                                name="titleButton" 
                                value={ titleButton ?? '' } 
                                onChange={ ({ target }) => {
                                    const{ value, name, type } = target;
                                    setterInput(name, value);
                                }  }/>
                                {
                                    eNotification?.titleButton && ( 
                                     <label className='mt-2 text text-danger'>{ eNotification?.titleButton[0] } </label>
                                    )
                                }
                        </div>
                        <div className="col-md-12 mb-3">
                            <div style={{ display:'flex', alignItems:'center' }}>
                                <label htmlFor="forTitle" className="form-label"><strong>Enlace del boton</strong></label>
                                <VarInput name='linkButton' textInput={ linkButton } handleChange={ handleChange } />
                            </div>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="linkButtonId" 
                                placeholder="Enlace del boton" 
                                name="linkButton" 
                                value={ linkButton ?? '' } 
                                onChange={ ({ target }) => {
                                    const{ value, name, type } = target;
                                    setterInput(name, value);
                                }}/>
                                {
                                    eNotification?.linkButton && ( 
                                     <label className='mt-2 text text-danger'>{ eNotification?.linkButton[0] } </label>
                                    )
                                }
                        </div>
                        </>
                    )
                }
                <div className="col-md-12 mb-3" style={{ textAlign:'right' }}>
                    {
                        parseInt(notification.step) == 1 ? (
                            <button className='btn btn-primary-oo'>Guardar</button>
                        ) : (
                            <button className='btn btn-primary-oo'>Editar</button>
                        )
                    }
                </div>
                
            </form>
        </div>
        <div className="col-md-4">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="flex-1" style={{ position:'relative' }}>
                <img 
                src={"/img/notificacion.jpg"}
                style={{
                    width: '270px',
                    height: 'auto',
                    borderRadius:'12px',
                    boxShadow: "rgb(38, 57, 77) 0px 20px 20px -10px"
                }}
                />
                <div style={{ position:'absolute', top:80, left:20, right:20}}>
                    <label htmlFor="" style={{ fontSize:'12px', fontWeight:'bold' }}>{title}</label>
                    <p style={{ fontSize:'10px', fontWeight:'bold', marginTop:20, whiteSpace:'pre-line', wordBreak:'break-word' }}>
                        { description }
                    </p>
                    {
                        (isActionButton) && (
                            <div style={{ display:'flex', justifyContent:'center', alignItems:'center' }}>
                                <button style={{
                                    fontSize:'10px',
                                    borderRadius:'50px',
                                    backgroundColor:'#545be9',
                                    border:'0',
                                    padding:'5px 20px',
                                    color:'white',
                                    fontWeight:'bold'
                                }}>
                                    { titleButton }
                                </button>

                            </div>
                        )
                    }
                </div>
            </div>
        </div>
        </div>
        </div>
    )
}
