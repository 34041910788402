import { Button, Menu, MenuItem } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useExportPanelists, usePanelistsStore } from "../../../../store/PanelistsInsight/usePanelistsStore";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CampaignIcon from '@mui/icons-material/Campaign';
import DatasetIcon from '@mui/icons-material/Dataset';
import { DateTimeFormExportModal } from "../modal/DateTimeFormExportModal";
import { CanpaignExportModal } from "../modal/CampaignExportModal";

export const MenuTablePanelists = ({ columnFilters, setColumnFilters, isLoadingPanelists, setIsLoadingPanelists }) => {
    const debounceExport = useRef({ panelist: null, campaign: null, ranking: null });
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [exportType, setExportType] = useState('');
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };
    const {
        total
    } = usePanelistsStore((state) => state);
    const {
        fetchInitExport,
        fetchGetExportStatus,
        exportStatus,
    } = useExportPanelists((state) => state);

    const handleExportPanelists = async () => {
        await fetchInitExport(null, 'panelist', columnFilters, total);
        handleClose();
    }

    const handleExportTypeInterval = (type) => {
        let hash;
        if (type === 'campaign') {
            hash = exportStatus.campaign.hash;
        } else if (type === 'panelist') {
            hash = exportStatus.panelist.hash;
        } else if (type === 'ranking') {
            hash = exportStatus.ranking.hash;
        } else if (type === 'potential') {
            hash = exportStatus.potential.hash;
        }

        if (hash === "") {
            clearInterval(debounceExport.current[type]);
            return;
        }

        if (debounceExport.current[type] != null) {
            clearInterval(debounceExport.current[type]);
        }

        debounceExport.current[type] = setInterval(() => {
            const operationData = {
                type: type,
                hash: hash
            };
            fetchGetExportStatus(operationData);
        }, 10000);
    };

    useEffect(() => {
        handleExportTypeInterval('panelist');
        handleExportTypeInterval('ranking');
        handleExportTypeInterval('campaign');
        handleExportTypeInterval('potential');
    }, [
        exportStatus.panelist.hash,
        exportStatus.ranking.hash,
        exportStatus.campaign.hash,
        exportStatus.potential.hash
    ]);

    return (
        <>
            {openModal && exportType != 'campaign' && (
                <DateTimeFormExportModal
                    setOpen={setOpenModal}
                    open={openModal}
                    exportType={exportType}
                />
            )}
            {openModal && exportType == 'campaign' && (
                <CanpaignExportModal
                    setOpen={setOpenModal}
                    open={openModal}
                />
            )}
            <div>
                <Button
                    key={'download-btn-key'}
                    id="basic-button"
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleClick}
                    startIcon={<FileDownloadIcon />}
                    color="inherit"
                >
                    Descargar
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        sx={{
                            padding: "10px"
                        }}
                        onClick={() => handleExportPanelists()}
                        disabled={columnFilters.length === 0 || isLoadingPanelists}
                    >
                        <FilterAltIcon sx={{ paddingRight: "5px" }} />
                        Selección filtro
                    </MenuItem>
                    <MenuItem
                        sx={{
                            padding: "10px"
                        }}
                        onClick={() => {
                            setOpenModal(!openModal);
                            setExportType('ranking');
                            handleClose();
                        }}
                        disabled={exportStatus.ranking.status && exportStatus.ranking.severity === 'info'}
                    >
                        <PeopleAltIcon sx={{ paddingRight: "5px" }} />
                        Ranking panelistas del mes
                    </MenuItem>
                    <MenuItem
                        sx={{
                            padding: "10px"
                        }}
                        onClick={() => {
                            setOpenModal(!openModal);
                            setExportType('campaign');
                            handleClose();
                        }}
                        disabled={exportStatus.campaign.status && exportStatus.campaign.severity === 'info'}
                    >
                        <CampaignIcon sx={{ paddingRight: "5px" }} />
                        Campañas
                    </MenuItem>
                    <MenuItem
                        sx={{
                            padding: "10px"
                        }}
                        onClick={() => {
                            setOpenModal(!openModal);
                            setExportType('potential');
                            handleClose();
                        }}
                        disabled={exportStatus.potential.status && exportStatus.potential.severity === 'info'}
                    >
                        <DatasetIcon sx={{ paddingRight: "5px" }} />
                        Base Potencial
                    </MenuItem>
                </Menu>
                <Button
                    key={'reset-filters-btn-key'}
                    id="reset-filters"
                    onClick={() => {
                        setIsLoadingPanelists(true)
                        setColumnFilters([])
                    }}
                    startIcon={<RestartAltIcon />}
                    color="inherit"
                >
                    Limpiar filtros
                </Button>
            </div>
        </>
    )
}