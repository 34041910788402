import { create } from "zustand";
import {
    addPointsToPanelist,
    deleteExportedFile,
    downloadExportedFile,
    exportPanelistRanking,
    exportPanelists,
    exportPotentialBase,
    exportRegisterCampaigns,
    getAvailablesExports,
    getExportStatus,
    getPanelistPoints,
    getPanelists,
    updatePanelist
} from "../../api/sip/panelists";
import { persist } from "zustand/middleware";
import { format } from "date-fns";

let URL_PANELISTS = new URL(`${import.meta.env.VITE_REACT_APP_URL}/marketing/panelists`);

export const usePanelistsStore = create((set, get) => {
    return {
        panelists: [],
        panelist: {},
        token: "",
        setToken: (newToken) => set(newToken),
        setPanelist: (newPanelist) => set({ panelist: { ...newPanelist } }),
        total: 0,
        isLoadingPanelists: true,
        setIsLoadingPanelists: (newValue) => set({ isLoadingPanelists: newValue }),
        isLoadingPanelist: true,
        isLoadingGetPoints: true,
        isError: { status: false, message: "" },
        isErrorUpdate: { status: false, message: "" },
        isErrorPoints: { status: false, message: "" },
        isErrorAddPoints: { status: false, message: "" },
        setIsError: (newIsError) => set({ isError: { ...newIsError } }),
        fetchPanelists: async (pagination = { pageIndex: 0, pageSize: 10 }, columnFilters = [], sorting = null, signal = null) => {
            set({ isLoadingPanelists: true, isError: { status: false, message: "" }, total: 0 });
            try {
                if (URL_PANELISTS.searchParams.has('download')) {
                    URL_PANELISTS.searchParams.delete('download', 'filtered');
                }
                URL_PANELISTS.searchParams.set('perpage', `${pagination.pageSize}`);
                URL_PANELISTS.searchParams.set('page', pagination.pageIndex + 1);
                URL_PANELISTS.searchParams.set('filters', columnFilters.length > 0 ? JSON.stringify(columnFilters) : '');
                URL_PANELISTS.searchParams.set('sortBy', sorting.length > 0 ? sorting[0].id : '');
                URL_PANELISTS.searchParams.set('sortOrder', sorting.length > 0 ? (sorting[0].desc ? 'desc' : 'asc') : '');
                const { status, data } = await getPanelists(URL_PANELISTS, signal);
                set({
                    isLoadingPanelists: false,
                    panelists: status === 200 ? data?.data : [],
                    total: data?.total
                });
            } catch (error) {
                set({
                    isLoadingPanelists: false,
                    isError: { status: true, message: error.data?.message },
                });
            }
        },
        fetchUpdatePanelists: async (toUpdate) => {
            set({ isErrorUpdate: { status: false, message: "" } });
            try {
                const { status, data } = await updatePanelist(toUpdate);
                return { status, message: data.message };
            } catch (error) {
                set({
                    isErrorUpdate: { status: true, message: error.data },
                });
            }
        },
        fetchPanelistPoints: async (token) => {
            set({ isLoadingGetPoints: true, panelist: { ...get().panelist }, isErrorPoints: { status: false, message: "" } });
            try {
                const { status, data } = await getPanelistPoints(token);
                if (status != 200) {
                    set({
                        isLoadingGetPoints: false,
                        isErrorPoints: { status: true, message: data.message },
                    });
                    return;
                }
                set({
                    isLoadingGetPoints: false,
                    panelist: status === 200 ? { ...get().panelist, points: data.points } : { ...get().panelist },
                });
                return data.points;
            } catch (error) {
                set({
                    isLoadingGetPoints: false,
                    isErrorPoints: { status: true, message: error.data?.message },
                });
            }
        },
        fetchAddPointsToPanelist: async (addPoints) => {
            set({ isErrorAddPoints: { status: false, message: "" } });
            try {
                const { status, data } = await addPointsToPanelist(addPoints);
                return { status, message: data?.message };
            } catch (error) {
                set({
                    isErrorAddPoints: { status: true, message: error.data },
                });
            }
        },
    }
});

const initExportStatusState = { status: false, severity: 'info', message: [], isError: false, errors: {}, hash: "", filename: "", downloadFilename: "" };

export const useExportPanelists = create(
    persist((set, get) => ({
        exportStatus: {
            panelist: initExportStatusState,
            ranking: initExportStatusState,
            campaign: initExportStatusState,
            potential: initExportStatusState,
        },
        availableExports: [],
        availableExportsError: {
            status: false,
            severity: 'error',
            message: ""
        },
        availableExportsIsloading: true,
        resetExportStatus: (type) => set({
            exportStatus: {
                ...get().exportStatus,
                [type]: initExportStatusState
            }
        }),
        closeExportStatusAlert: (type) => set({
            exportStatus: {
                ...get().exportStatus,
                [type]: { ...get().exportStatus[type], status: false }
            }
        }),
        fetchInitExport: async (filters, type, columnFilters = []) => {
            set({ exportStatus: { ...get().exportStatus, [type]: initExportStatusState } });
            try {
                const now = new Date();
                const timestamp = format(now, 'ddMMyyyyHHmmss');
                const downloadFilename = `${type}-${timestamp}.xlsx`;
                const init_export = {
                    ...filters,
                    exportType: type,
                    downloadFilename
                };
                let response;
                if (type == 'campaign') {
                    console.log("initexport", init_export)
                    response = await exportRegisterCampaigns(init_export);
                } else if (type == 'ranking') {
                    response = await exportPanelistRanking(init_export);
                } else if (type == 'potential') {
                    response = await exportPotentialBase(init_export);
                } else if (type == 'panelist' && columnFilters) {
                    URL_PANELISTS.searchParams.set('download', 'filtered');
                    URL_PANELISTS.searchParams.set('filters', columnFilters.length > 0 ? JSON.stringify(columnFilters) : '');
                    response = await exportPanelists(URL_PANELISTS);
                }

                if (response && response.status == 200) {
                    set({
                        exportStatus: {
                            ...get().exportStatus,
                            [type]: {
                                status: true,
                                severity: 'info',
                                message: [response?.data?.message ?? "Preparando resultados..."],
                                isError: false,
                                errors: {},
                                hash: response?.data?.hash ?? "",
                                filename: response?.data?.hash ?? "",
                                downloadFilename: downloadFilename ?? ""
                            }
                        }
                    });
                }
                return response;
            } catch (error) {
                if (error.request.status === 500) {
                    set({
                        exportStatus: {
                            ...get().exportStatus,
                            [type]: {
                                status: true,
                                severity: 'error',
                                message: ["Ha ocurrido un error inesperado, por favor intente nuevamente."],
                                isError: false,
                                errors: {},
                                hash: "",
                                filename: "",
                                downloadFilename: ""
                            }
                        }
                    });
                } else {
                    set({
                        exportStatus: {
                            ...get().exportStatus,
                            [type]: {
                                status: false,
                                severity: 'error',
                                message: [],
                                isError: true,
                                errors: error.data.errors ?? {},
                                hash: "",
                                filename: "",
                                downloadFilename: ""
                            }
                        }
                    });
                }
                return error;
            }
        },
        fetchGetExportStatus: async (operationData) => {
            try {
                const { status, data } = await getExportStatus(operationData);
                if (data.status == 'no_data') {
                    set({
                        exportStatus: {
                            ...get().exportStatus,
                            [operationData.type]: {
                                ...get().exportStatus[operationData.type],
                                status: true,
                                severity: 'warning',
                                message: [data.message ?? 'No hay datos para exportar...'],
                                isError: false,
                                errors: {},
                                hash: "",
                                filename: data.hash ?? "",
                            }
                        }
                    });
                }
                if (data.status == 'export_in_progress') {
                    set({
                        exportStatus: {
                            ...get().exportStatus,
                            [operationData.type]: {
                                ...get().exportStatus[operationData.type],
                                status: true,
                                severity: 'info',
                                message: [`${data.message}...${data.progress}%` ?? "Exportando datos..."],
                                isError: false,
                                errors: {},
                                hash: data.hash,
                                filename: data.hash ?? "",
                            }
                        }
                    });
                }
                if (data.status == 'export_finished') {
                    set({
                        exportStatus: {
                            ...get().exportStatus,
                            [operationData.type]: {
                                ...get().exportStatus[operationData.type],
                                status: true,
                                severity: 'success',
                                message: [data.message ?? "La exportación se ha completado"],
                                isError: false,
                                errors: {},
                                hash: "",
                                filename: data.hash ?? ""
                            }
                        }
                    });
                }
            } catch (error) {
                set({
                    exportStatus: {
                        ...get().exportStatus,
                        [operationData.type]: {
                            ...get().exportStatus[operationData.type],
                            status: true,
                            severity: 'error',
                            message: ["Ha ocurrido un error inesperado. Intente exportar nuevamente."],
                            isError: false,
                            errors: {},
                            hash: "",
                            filename: operationData?.hash ?? ""
                        }
                    }
                });
            }
        },
        fetchDownloadExportedFile: async (hash, type, downloadFilename) => {
            try {
                const response = await downloadExportedFile(hash);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', downloadFilename);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                set({
                    exportStatus: {
                        ...get().exportStatus,
                        [type]: {
                            ...get().exportStatus[type],
                            status: true,
                            severity: 'warning',
                            message: [error?.data?.message ?? 'El archivo ya no está disponible. Intente exportar nuevamente.'],
                            isError: false,
                            errors: {},
                            hash: hash,
                            filename: hash
                        }
                    }
                });
                return error.status
            }
        },
        fetchDeleteExportedFile: async (hash, type) => {
            const _data = { hash: hash }
            try {
                const response = await deleteExportedFile(_data);
                return response;
            } catch (error) {
                set({
                    exportStatus: {
                        ...get().exportStatus,
                        [type]: {
                            ...get().exportStatus[type],
                            status: true,
                            severity: 'warning',
                            message: [error.data.message ?? 'El archivo ya no existe, puedes cerrar este mensaje.'],
                            isError: false,
                            errors: {},
                            hash: hash,
                            filename: hash,
                        }
                    }
                });
            }
        },
        fetchGetAvailableExports: async () => {
            try {
                set({ availableExportsIsloading: true })
                const { status, data } = await getAvailablesExports();
                if (status === 200) {
                    set({
                        availableExports: data ?? [],
                        availableExportsError: {
                            status: false,
                            message: ""
                        },
                        availableExportsIsloading: false,
                    });
                }
            } catch (error) {
                set({
                    availableExports: [],
                    availableExportsError: {
                        status: true,
                        message: error?.data?.message ?? ''
                    },
                    availableExportsIsloading: false,
                });
            }
        }
    }),
        {
            name: 'export-status',
            partialize: (state) => ({
                exportStatus: state.exportStatus,
            })
        })
);