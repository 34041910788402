import { axiosApi } from "../axiosApi";

export const getPanelists = async (url, signal = null) => {
    try {
        const response = await axiosApi.get(url, {
            signal,
        });
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getPanelistPoints = async (token) => {
    try {
        const response = await axiosApi.get(`/api/marketing/panelists/points/${token}`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const updatePanelist = async (panelist) => {
    try {
        const response = await axiosApi.put('/api/marketing/panelists/update', panelist);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const addPointsToPanelist = async (addPoints) => {
    try {
        const response = await axiosApi.post('/api/marketing/panelists/points/add', addPoints);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const exportPanelists = async (url) => {
    try {
        const response = await axiosApi.get(url);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const exportPanelistRanking = async (dateRange) => {
    try {
        const response = await axiosApi.post('/api/marketing/panelists/export-ranking', dateRange);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const exportRegisterCampaigns = async (dateRange) => {
    try {
        const response = await axiosApi.post('/api/marketing/panelists/export-campaigns', dateRange);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const exportPotentialBase = async (dateRange) => {
    try {
        const response = axiosApi.post(`/api/marketing/panelists/export-potential-base`, dateRange);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getExportStatus = async (operationData) => {
    try {
        const response = await axiosApi.get(
            `/api/marketing/panelists/export-status/${operationData.type}/${operationData.hash}`
        );
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const downloadExportedFile = async (hash) => {
    try {
        const response = await axiosApi.get(`/api/marketing/panelists/download-export/${hash}`, {
            responseType: 'blob',
        });
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const deleteExportedFile = async (hash) => {
    try {
        const response = await axiosApi.post(
            `/api/marketing/panelists/delete-export`,
            hash
        );
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getAvailablesExports = async () => {
    try {
        const response = await axiosApi.get('/api/marketing/panelists/get-exports');
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};