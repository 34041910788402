import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { usePanelistsStore } from "../../../../store/PanelistsInsight/usePanelistsStore";
// import { PANELIST_COLUMNS } from "./PanelistsTableColumn";
import { PanelistsTableRowActionMenuItems } from "./PanelistsTableRowActionMenuItems";
import { MenuTablePanelists } from "./MenuTablePanelists";
import { Autocomplete, Box } from "@mui/material";
import { ExportAlertStack } from "./ExportAlertStack";
import { TextField } from "@mui/material";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { RrssListItem, RrssIcon } from '../ui/RrssListItem';
import { geoTitles } from '../../../../helper/geo';

import {
    PANELIST_ACTIVITY_VALUES,
    PANELIST_GENDER_VALUES,
    PANELIST_RRSS_VALUES,
    PANELIST_SINO_VALUES,
    PANELIST_STATUS_VALUES,
    PANELIST_TYPE_VALUES
} from "../../../../helper/panelists";
import { useGetGeographiesAjax } from "../../../../hooks/useGeography";
import { AuthUserContext } from "../../../../context/AuthUserContext";
import { useRefenceNse } from "../../../../hooks/useNse";


export const PanelistsTable = ({
    seeModal,
    setSeeModal,
    setPanelist,
    columnFilters,
    setColumnFilters,
    pagination,
    setPagination }) => {

    const { authUser } = useContext(AuthUserContext);

    const {
        panelists,
        total: rowCount,
        isLoadingPanelists,
        setIsLoadingPanelists,
        isError,
        fetchPanelists
    } = usePanelistsStore((state) => state);

    const [sorting, setSorting] = useState([]);

    //Get geographies valu
    const [geo, setGeo] = useState({
        firstgeo: [],
        secondgeo: [],
        thirdgeo: []
    });

    //Traemos datos para rellenar opciones de los select geo y nse
    const { isLoading: isLoadingFirstGeo, selectGeographies: selectFirstGeo } = useGetGeographiesAjax({ value: 1 });
    const { isLoading: isLoadingSecondGeo, selectGeographies: selectSecondGeo } = useGetGeographiesAjax({ value: 2 });
    const { isLoading: isLoadingNse, typesNse } = useRefenceNse();
    useEffect(() => {
        if (!isLoadingFirstGeo && selectFirstGeo.options && geo.firstgeo.length === 0) {
            setGeo({ ...geo, firstgeo: selectFirstGeo.options.map(fg => ({ text: fg.label, value: fg.value })) })
        }
        if (!isLoadingSecondGeo && selectSecondGeo.options && geo.secondgeo.length === 0) {
            setGeo({ ...geo, secondgeo: selectSecondGeo.options.map(sg => ({ text: sg.label, value: sg.value })) })
        }
    }, [selectFirstGeo, selectSecondGeo, geo])

    if (['PE', 'AR', 'EC'].includes(authUser?.dashboardByCountry)) {
        const { isLoading: isLoadingThirdGeo, selectGeographies: selectThirdGeo } = useGetGeographiesAjax({ value: 3 });
        useEffect(() => {
            if (!isLoadingThirdGeo && selectThirdGeo.options && geo.thirdgeo.length === 0) {
                setGeo({ ...geo, thirdgeo: selectThirdGeo.options.map(tg => ({ text: tg.label, value: tg.value })) })
            }
        }, [selectThirdGeo, geo])
    }

    //Debounce ref
    const debouncedRef = useRef(500);
    //Referencia para identificar valores previos y comparar.
    const previousValues = useRef({
        paginationPageIndex: pagination.pageIndex,
        paginationPageSize: pagination.pageSize,
        columnFilters,
        sorting
    });

    let PANELIST_COLUMNS = [
        {
            id: "status",
            accessorKey: "status",
            size: 50,
            header: "Estado",
            filterVariant: "multi-select",
            filterSelectOptions: Object.entries(PANELIST_STATUS_VALUES).map(
                ([clave, valor]) => ({ text: valor, value: clave })
            ),
            Cell: ({ cell }) => {
                return PANELIST_STATUS_VALUES[cell.getValue()];
            }
        },
        {
            id: "type",
            accessorKey: "type",
            size: 50,
            header: "Tipo",
            filterVariant: "multi-select",
            filterSelectOptions: Object.entries(PANELIST_TYPE_VALUES).map(
                ([clave, valor]) => ({ text: valor, value: clave })
            ),
            Cell: ({ cell }) => {
                return PANELIST_TYPE_VALUES[cell.getValue()];
            }
        },
        {
            id: "name",
            accessorKey: "name",
            size: 100,
            header: "Nombre",
            filterVariant: "text",
        },
        {
            id: "email",
            accessorKey: "email",
            header: "Email",
            filterVariant: "text",
        },
        {
            id: "token",
            accessorKey: "token",
            header: "Token",
            filterVariant: "text",
        },
        {
            id: "dni",
            accessorKey: "dni",
            size: 50,
            header: "DNI / RUT",
            filterVariant: "text",
        },
        {
            id: "phone",
            accessorKey: "phone",
            size: 50,
            header: "Teléfono",
            filterVariant: "text",
        },
        {
            id: "gender",
            accessorKey: "gender",
            header: "Género",
            size: 20,
            filterVariant: "multi-select",
            filterSelectOptions: Object.entries(PANELIST_GENDER_VALUES).map(
                ([clave, valor]) => ({ text: valor, value: clave })
            ),
            Cell: ({ cell }) => {
                return PANELIST_GENDER_VALUES[cell.getValue()];
            }
        },
        {
            id: "age",
            accessorFn: (originalRow) => parseInt(originalRow.age),
            accessorKey: "age",
            header: 'Edad',
            filterVariant: 'range-slider',
            muiFilterSliderProps: {
                min: 0,
                max: 130
            },
            size: 200,
        },
        {
            id: "firstnse",
            accessorKey: "firstnse",
            header: authUser?.dashboardByCountry === 'CL' ? 'ESOMAR' : 'NSE',
            size: 20,
            enableSorting: false,
            filterVariant: "multi-select",
            filterSelectOptions: !isLoadingNse ? [...typesNse.values[authUser?.dashboardByCountry === 'CL' ? 'esomar' : 'nse'].map(x => ({ text: x.value, value: x.value })), { text: 'No perfilado', value: 0 }] : [],
            Cell: ({ cell }) => cell.getValue() || 'No perfilado',
        },
        {
            id: "secondnse",
            accessorKey: "secondnse",
            header: 'AIM',
            size: 20,
            enableSorting: false,
            filterVariant: "multi-select",
            filterSelectOptions: !isLoadingNse && authUser?.dashboardByCountry === 'CL' ? [...typesNse.values['aim'].map(x => ({ text: x.value, value: x.value })), { text: 'No perfilado', value: 0 }] : [],
            Cell: ({ cell }) => cell.getValue() || 'No perfilado',
        },
        {
            id: "firstgeo",
            accessorKey: "firstgeo",
            header: geoTitles[authUser?.dashboardByCountry]?.firstgeo || 'Región',
            size: 20,
            filterVariant: "multi-select",
            filterSelectOptions: geo.firstgeo || [],
            Cell: ({ cell }) => cell.getValue(),
            Filter: ({ column }) => {
                const { setFilterValue, getFilterValue } = column;
                const currentFilterValue = getFilterValue() || [];
                return (
                    <Autocomplete
                        key={"firstgeo"}
                        multiple
                        options={geo.firstgeo || []}
                        value={geo.firstgeo?.filter((item) => currentFilterValue.includes(item.value)) || []}
                        getOptionLabel={(option) => option.text}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        filterSelectedOptions
                        onChange={(event, newValue) => {
                            const selectedValues = newValue.map((item) => item.value);
                            setFilterValue(selectedValues)
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} variant="standard" placeholder="Buscar..." />}
                    />
                );
            },
        },
        {
            id: "secondgeo",
            accessorKey: "secondgeo",
            header: geoTitles[authUser?.dashboardByCountry]?.secondgeo || 'Comuna',
            size: 20,
            filterVariant: "multi-select",
            filterSelectOptions: geo.secondgeo || [],
            Cell: ({ cell }) => cell.getValue(),
            Filter: ({ column }) => {
                const { setFilterValue, getFilterValue } = column;
                const currentFilterValue = getFilterValue() || [];
                return (
                    <Autocomplete
                        key={"secondgeo"}
                        multiple
                        options={geo.secondgeo || []}
                        value={geo.firstgeo?.filter((item) => currentFilterValue.includes(item.value)) || []}
                        getOptionLabel={(option) => option.text}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        filterSelectedOptions
                        onChange={(event, newValue) => {
                            const selectedValues = newValue.map((item) => item.value);
                            setFilterValue(selectedValues)
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} variant="standard" placeholder="Buscar..." />}
                    />
                );
            },
        },
        {
            id: "thirdgeo",
            accessorKey: "thirdgeo",
            header: geoTitles[authUser?.dashboardByCountry]?.thirdgeo || 'Distrito',
            size: 20,
            enableHiding: !['PE', 'AR', 'EC'].includes(authUser?.dashboardByCountry) ? false : true,
            filterVariant: "multi-select",
            filterSelectOptions: geo.thirdgeo || [],
            Cell: ({ cell }) => cell.getValue(),
            Filter: ({ column }) => {
                const { setFilterValue, getFilterValue } = column;
                const currentFilterValue = getFilterValue() || [];
                return (
                    <Autocomplete
                        key={"thirdgeo"}
                        multiple
                        options={geo.thirdgeo || []}
                        value={geo.firstgeo?.filter((item) => currentFilterValue.includes(item.value)) || []}
                        getOptionLabel={(option) => option.text}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        filterSelectedOptions
                        onChange={(event, newValue) => {
                            const selectedValues = newValue.map((item) => item.value);
                            setFilterValue(selectedValues)
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} variant="standard" placeholder="Buscar..." />}
                    />
                );
            },
        },
        {
            id: 'birthdate',
            accessorFn: (originalRow) => new Date(parseISO(originalRow.birthdate)),
            accessorKey: 'birthdate',
            header: 'Fecha de Cumpleaños',
            filterVariant: 'date',
            size: 100,
            Cell: ({ cell }) => format(cell.getValue(), "dd 'de' MMMM", { locale: es }),
            muiFilterDatePickerProps: {
                renderInput: (params) => <TextField {...params} size="small" />,
                inputFormat: "dd 'de' MMMM",
                disableMaskedInput: true,
                views: ['month', 'day'],
            },
        },
        {
            id: 'registration_at',
            accessorFn: (originalRow) => originalRow.registration_at
                ? new Date(originalRow.registration_at)
                : null,
            header: 'Fecha de Registro',
            filterVariant: 'datetime-range',
            size: 400,
            Cell: ({ cell }) => {
                const value = cell.getValue();
                if (!value) return '—';
                return format(value, 'dd-MM-yyyy HH:mm');
            },
            muiFilterDateTimePickerProps: {
                renderInput: (params) => <TextField {...params} size="small" />,
                inputFormat: "dd-MM-yyyy HH:mm",
            },
        },
        {
            id: "doubleOptin",
            accessorKey: "doubleOptin",
            header: "¿Email verificado?",
            size: 20,
            filterVariant: "multi-select",
            filterSelectOptions: Object.entries(PANELIST_SINO_VALUES).map(
                ([clave, valor]) => ({ text: valor, value: clave })
            ),
            Cell: ({ cell }) => {
                let value = (cell.getValue() === '' || Number(cell.getValue()) === 0) ? 0 : 1;
                return PANELIST_SINO_VALUES[value];
            }
        },
        {
            id: "isProfiled",
            accessorKey: "isProfiled",
            header: "¿Está perfilado?",
            size: 20,
            filterVariant: "multi-select",
            filterSelectOptions: Object.entries(PANELIST_SINO_VALUES).map(
                ([clave, valor]) => ({ text: valor, value: clave })
            ),
            Cell: ({ cell }) => {
                let value = (cell.getValue() === '' || Number(cell.getValue()) === 0) ? 0 : 1;
                return PANELIST_SINO_VALUES[value];
            }
        },
        {
            id: 'last_interaction',
            accessorFn: (originalRow) => originalRow.last_interaction
                ? new Date(originalRow.last_interaction)
                : null,
            header: 'Última interacción',
            filterVariant: 'datetime-range',
            size: 400,
            Cell: ({ cell }) => {
                const value = cell.getValue();
                if (!value) return '—';
                return format(value, 'dd-MM-yyyy HH:mm');
            },
            muiFilterDateTimePickerProps: {
                renderInput: (params) => <TextField {...params} size="small" />,
                inputFormat: "dd-MM-yyyy HH:mm",
            },
        },
        {
            id: "activity",
            accessorKey: "activity",
            size: 50,
            header: "Actividad",
            filterVariant: "multi-select",
            filterSelectOptions: Object.entries(PANELIST_ACTIVITY_VALUES).map(
                ([clave, valor]) => ({ text: valor, value: clave })
            ),
            Cell: ({ cell }) => {
                return PANELIST_ACTIVITY_VALUES[cell.getValue()];
            }
        },
        {
            id: "rrss",
            accessorKey: "rrss",
            header: "Redes Sociales",
            size: 200,
            filterVariant: "multi-select",
            filterSelectOptions: Object.entries(PANELIST_RRSS_VALUES).map(
                ([clave, valor]) => ({ text: valor, value: clave })
            ),
            Cell: ({ cell }) => {
                const rrss = Object.fromEntries(
                    Object.entries(JSON.parse(cell.getValue()) || {})
                        .filter(([key, value]) => value != null)
                );
                const rrssKeys = Object.keys(rrss);
                return (
                    <>
                        {
                            rrssKeys.length > 0 ?
                                (
                                    rrssKeys.map(key => {
                                        if (rrss[key]) {
                                            return (
                                                <RrssListItem
                                                    key={key}
                                                    href={rrss[key]}
                                                    icon={<RrssIcon rrss={key} />}
                                                />
                                            );
                                        }
                                        return null;
                                    })
                                )
                                : (
                                    "Sin redes sociales"
                                )
                        }
                    </>
                )
            }
        },
        {
            id: "motivation",
            accessorKey: "motivation",
            header: "Razón embajador",
            size: 300,
            filterVariant: "text"
        },
        {
            id: 'candidate_at',
            accessorFn: (originalRow) => originalRow.candidate_at
                ? new Date(originalRow.candidate_at)
                : null,
            header: 'Fecha postulación a embajador',
            filterVariant: 'datetime-range',
            size: 400,
            Cell: ({ cell }) => {
                const value = cell.getValue();
                if (!value) return '—';
                return format(value, 'dd-MM-yyyy HH:mm');
            },
            muiFilterDateTimePickerProps: {
                renderInput: (params) => <TextField {...params} size="small" />,
                inputFormat: "dd-MM-yyyy HH:mm",
            },
        },
        {
            id: 'accepted_at',
            accessorFn: (originalRow) => originalRow.accepted_at
                ? new Date(originalRow.accepted_at)
                : null,
            header: 'Fecha aceptación embajador',
            filterVariant: 'datetime-range',
            size: 400,
            Cell: ({ cell }) => {
                const value = cell.getValue();
                if (!value) return '—';
                return format(value, 'dd-MM-yyyy HH:mm');
            },
            muiFilterDateTimePickerProps: {
                renderInput: (params) => <TextField {...params} size="small" />,
                inputFormat: "dd-MM-yyyy HH:mm",
            },
        },
        {
            id: 'ambassador_end_at',
            accessorFn: (originalRow) => originalRow.ambassador_end_at
                ? new Date(originalRow.ambassador_end_at)
                : null,
            header: 'Fecha de término embajador',
            filterVariant: 'datetime-range',
            size: 400,
            Cell: ({ cell }) => {
                const value = cell.getValue();
                if (!value) return '—';
                return format(value, 'dd-MM-yyyy HH:mm');
            },
            muiFilterDateTimePickerProps: {
                renderInput: (params) => <TextField {...params} size="small" />,
                inputFormat: "dd-MM-yyyy HH:mm",
            },
        }
    ];

    if (authUser?.dashboardByCountry === 'CL') {
        PANELIST_COLUMNS = PANELIST_COLUMNS.filter((col) => col.id !== 'thirdgeo');
    } else {
        PANELIST_COLUMNS = PANELIST_COLUMNS.filter((col) => col.id !== 'secondnse');
    }

    const columns = useMemo(() => PANELIST_COLUMNS, [geo]);

    const table = useMaterialReactTable({
        columns,
        data: panelists,
        isClearable: true,
        enableHiding: true,
        initialState: {
            density: 'compact',
            showColumnFilters: true,
            columnVisibility: {
                name: false,
                gender: false,
                last_interaction: false,
                registration_at: false,
                doubleOptin: false,
                isProfiled: false,
                birthdate: false,
                token: false,
                phone: false,
                candidate_at: false,
                accepted_at: false,
                ambassador_end_at: false,
                motivation: false,
                rrss: false,
                age: false,
                firstgeo: false,
                secondgeo: false,
                thirdgeo: false,
            },
            columnPinning: {
                left: ['mrt-row-actions', 'email'],
            }
        },
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        rowCount: rowCount,
        onColumnFiltersChange: setColumnFilters,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        enableGlobalFilter: false,
        enableColumnActions: true,
        enableColumnFilters: true,
        enableBottomToolbar: true,
        enableFullScreenToggle: true,
        enableDensityToggle: false,
        localization: MRT_Localization_ES,
        positionToolbarAlertBanner: 'top',
        muiToolbarAlertBannerProps: {
            color: 'error',
            severity: 'error',
            children: isError.message
        },
        muiTablePaperProps: {
            sx: { boxShadow: "none !important", border: "none" },
        },
        muiFilterTextFieldProps: {
            placeholder: 'Buscar...',
        },
        state: {
            isLoading: isLoadingPanelists,
            pagination,
            columnFilters,
            sorting,
            showAlertBanner: isError.status,
        },
        positionActionsColumn: "first",
        displayColumnDefOptions: {
            "mrt-row-actions": { header: "Acciones" },
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box>
                <MenuTablePanelists
                    isLoadingPanelists={isLoadingPanelists}
                    setIsLoadingPanelists={setIsLoadingPanelists}
                    columnFilters={table.getState().columnFilters}
                    setColumnFilters={setColumnFilters}
                />
            </Box>
        ),
        enableRowActions: true,
        renderRowActionMenuItems: ({ closeMenu, row }) => [
            <PanelistsTableRowActionMenuItems
                key={Math.random()}
                closeMenu={closeMenu}
                seeModal={seeModal}
                setSeeModal={setSeeModal}
                setPanelist={setPanelist}
                row={row}
            />
        ]
    });

    const handleTransformColumnFilters = (filters) => {
        const dateFilterIds = [
            'registration_at',
            'last_interaction',
            'candidate_at',
            'accepted_at',
            'ambassador_end_at'
        ];
        let _columnFilters = filters.map(filter => {
            if (dateFilterIds.includes(filter.id)) {
                let [startDate, endDate] = filter.value.map(date => date ? new Date(date) : '');
                const formatedStartDate = startDate == '' ? null : format(startDate, 'dd-MM-yyyy HH:mm')
                const formatedEndDate = endDate == '' ? null : format(endDate, 'dd-MM-yyyy HH:mm')
                return { ...filter, value: [formatedStartDate, formatedEndDate] }
            }
            return filter
        });
        return _columnFilters
    }

    useEffect(() => {
        //AbortController para cancelar petición en curso si es que inicia una nueva
        const controller = new AbortController();
        const signal = controller.signal;
        // previusValues es para identificar por separado si el estado cambia respecto a su valor anterior
        // asi puedo identificar si el useEffect se aplica por cambios en pagination o en columnFilters
        // y de esta manera, diferenciar el tiempo del setTimeOut a mas o menos tiempo segun corresponda
        // mejorando la experiencia de usuario al hacer click en "retrodecer" o "siguiente" pagina
        let time =
            previousValues.current.paginationPageIndex !== pagination.pageIndex
                || previousValues.current.paginationPageSize !== pagination.pageSize
                ? 100
                : 2000;
        if (debouncedRef != undefined) {
            clearTimeout(debouncedRef.current);
        }
        previousValues.current = {
            paginationPageIndex: pagination.pageIndex,
            paginationPageSize: pagination.pageSize,
            columnFilters,
            sorting
        };
        debouncedRef.current = setTimeout(() => {
            const _columnFilters = handleTransformColumnFilters(columnFilters);
            fetchPanelists(pagination, _columnFilters, sorting, signal);
        }, time);
        return () => {
            controller.abort(); // Abortar la consulta en curso.
            clearTimeout(debouncedRef.current);
        };
    }, [pagination.pageIndex, pagination.pageSize, columnFilters, sorting]);

    return (
        <>
            <ExportAlertStack />
            <MaterialReactTable table={table} />
        </>
    );
};