import { axiosApi } from "../axiosApi";

export const ajaxFilterPotentialPanelists = async (filter) => {
    const response = await axiosApi.post(`/api/base-potencial/${filter.project_id}/filtrar/panelistas-potenciales`,filter);
    return response;
};

export const ajaxLoadAvailables = (selected,requireExternalTokens) => {
    let config = {};
    if(requireExternalTokens){ config.header = { 'Content-Type': 'multipart/form-data' }; }
    return axiosApi.post(`/api/base-potencial/cargar/panelistas-disponibles`,selected,config);
}

export const getOperationFromCache = async (projectId,segmentId) => {
    const response = await axiosApi.get(`/api/base-potencial/get/operation/cache/proyecto/${ projectId }/segmento/${ segmentId }`);
    return response;
};

export const deleteOperationFromCache = async (projectId,segmentId) => {
    const response = await axiosApi.delete(`/api/base-potencial/eliminar/operation/cache/proyecto/${projectId}/segmento/${ segmentId }`);
    return response;
};

export const ajaxGetAvailablesSubset = (selected) => {
    return axiosApi.post(`/api/base-potencial/get/availables/subset-count`,selected);
}