
export const geoTitles = {
    CL: {
        firstgeo: "Región",
        secondgeo: "Comuna",
    },
    PE: {
        firstgeo: "Departamento",
        secondgeo: "Provincia",
        thirdgeo: "Distrito"
    },
}