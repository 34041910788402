import React, { useContext } from 'react';
import { DailyProgressLineChart } from './DailyProgressLineChart';
import { ItemProgress } from './ItemsProgress';
import { PanelExternalProgressTable } from './PanelExternalProgressTable';
import { QuotasTableProject } from './../table/QuotasTableProject';
import { TrackerTable } from './TrackerTable';
import { AuthUserContext } from "../../../../context/AuthUserContext";
import { useProjectStore } from "../../../../store/Project/useProjectStore"

export const ListItemsDahsboard = ({id, hash }) => {
    const { authUser } = useContext(AuthUserContext);
    return (
        <>
            <ItemProgress id = {id}  />
            <DailyProgressLineChart  id={id} hash={hash} />
            {
                !hash && (
                    <PanelExternalProgressTable id={ id } hash={hash}/>
                )
            }
            {
                authUser != undefined && (
                    <TrackerTable id = { id }/>
                )
            }
            <QuotasTableProject id={id} hash={hash}/>
        </>
    )
}

