import { useCallback, useEffect, useState } from "react";
import { endOfToday, format, startOfMonth } from "date-fns";
import { useExportPanelists } from "../../../../store/PanelistsInsight/usePanelistsStore";
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useSubscribeStore } from "../../../../store/Subscribe/useSubscribeStore";
import Skeleton from "react-loading-skeleton";

export const CanpaignExportModal = ({ open, setOpen }) => {

    const [dateFilter, setDateFilter] = useState({
        from: startOfMonth(new Date()),
        to: endOfToday()
    });
    const [selectedMotives, setSelectedMotives] = useState({
        options: [],
        selected: [{ value: 'active', label: 'Panelistas activos' }]
    });
    const {
        isLoading: motiveIsLoading,
        fetchGetMotives,
    } = useSubscribeStore(state => state);

    const {
        exportStatus,
        resetExportStatus,
        fetchInitExport,
    } = useExportPanelists((state) => state);

    const handleCloseDialog = () => {
        setOpen(!open)
        resetExportStatus('campaign')
    }
    const handleChangeDateTimePicker = (event, rangeType) => {
        setDateFilter({
            ...dateFilter,
            [rangeType]: event
        })
    }
    //iniciar la exportación de campañas
    const handleInitExport = useCallback(async (_filters) => {
        const _filter = {
            dateRange: {
                from: format(dateFilter.from, 'dd-MM-yyyy HH:mm'),
                to: format(dateFilter.to, 'dd-MM-yyyy HH:mm')
            },
            filters: _filters.selected,
        }
        const { status } = await fetchInitExport(_filter, 'campaign');
        if (status == 200) {
            setOpen(!open)
        }
    }, [dateFilter]);

    //mostrar errores de datetime inputs
    const getErrorHelperText = (exportStatus, rangeType) => {
        const { campaign } = exportStatus;
        console.log(campaign)
        // Verificar si hay errores
        const isCampaignError = campaign.isError && Object.keys(campaign.errors).length > 0;
        // Si hay errores se retorna el mensaje según corresponda (from o to)
        if (isCampaignError) {
            return campaign.errors[`dateRange.${rangeType}`]?.map((err, i) => <span key={i}>{err}</span>);
        }
        // Si no hay errores, retorna string vacío
        return '';
    };

    const handleSelectedMotives = (newValues) => {
        const allSelected = newValues.some(option => option.value === 'all');
        if (allSelected) {
            // Si "Todos" está seleccionado, ignorar otras opciones y dejar solo "Todos"
            setSelectedMotives({
                ...selectedMotives,
                selected: [{ value: 'all', label: 'Todos' }],
            });
        } else {
            // Si no está seleccionado "Todos", actualiza con las opciones seleccionadas
            setSelectedMotives({
                ...selectedMotives,
                selected: newValues,
            });
        }
    };

    const handleGetMotives = async () => {
        const { data, status } = await fetchGetMotives('all');
        if (status === 200) {
            {
                setSelectedMotives({
                    ...selectedMotives,
                    options: [
                        { value: 'all', label: 'Todos los panelistas' },
                        { value: 'active', label: 'Panelistas activos' },
                        { value: 'inactive', label: 'Panelistas inactivos' },
                        ...data.motives
                    ]
                })
            }
        }
    };

    useEffect(() => {
        handleGetMotives();
    }, [])

    return (
        <Dialog
            key={'campaign'}
            open={open}
            onClose={() => setOpen(!open)}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    handleInitExport(selectedMotives);
                },
            }}
        >
            <DialogTitle>Seleccionar filtros</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: '30px' }}>
                    Selecciona los filtros para descargar los datos
                </DialogContentText>
                <DialogContentText sx={{ marginBottom: '15px', fontWeight: 'bold' }}>
                    Fecha de última interacción
                </DialogContentText>
                <Box sx={{ display: 'flex', gap: 2, marginBottom: '20px' }}>
                    <DateTimePicker
                        key={'datePicker-from'}
                        label="Desde"
                        onChange={(e) => handleChangeDateTimePicker(e, 'from')}
                        value={dateFilter.from}
                        renderInput={(params) =>
                        (<TextField
                            {...params}
                            size="small"
                            error={exportStatus.campaign.isError}
                            helperText={getErrorHelperText(exportStatus, 'from')}
                        />)
                        }
                        inputFormat="dd/MM/yyyy HH:mm"
                    />
                    <DateTimePicker
                        key={'datePicker-to'}
                        label="Hasta"
                        onChange={(e) => handleChangeDateTimePicker(e, 'to')}
                        value={dateFilter.to}
                        renderInput={(params) =>
                        (<TextField
                            {...params}
                            size="small"
                            error={exportStatus.campaign.isError}
                            helperText={getErrorHelperText(exportStatus, 'to')}
                        />)
                        }
                        inputFormat="dd/MM/yyyy HH:mm"
                    />
                </Box>
                <DialogContentText sx={{ marginBottom: '15px', fontWeight: 'bold' }}>
                    Estado de suscripción
                </DialogContentText>
                <FormControl sx={{ width: '100%' }}>
                    {motiveIsLoading && selectedMotives.options.length > 0
                        ? <Skeleton height={100} />
                        : (
                            <>
                                <Autocomplete
                                    id="motive-select"
                                    multiple
                                    options={selectedMotives.options ?? []}
                                    onChange={(event, newValues) => handleSelectedMotives(newValues)}
                                    getOptionLabel={(option) => option.label}
                                    value={selectedMotives.selected ?? []}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => <TextField {...params} placeholder="Selecciona una opción" />}
                                />
                                {exportStatus.campaign.isError
                                    && exportStatus.campaign.errors.filters.length > 0
                                    && exportStatus.campaign.errors.filters.map(err => <small className="text-danger mt-2">{err}</small>)}
                            </>
                        )}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseDialog()}>Cancelar</Button>
                <Button
                    type="submit"
                    sx={{
                        bgcolor: "#46166c",
                        '&:hover': {
                            backgroundColor: '#46166c',
                            opacity: 0.9
                        }
                    }}
                    variant="contained"
                >
                    Exportar
                </Button>
            </DialogActions>
        </Dialog>
    );
}