import React, { useEffect, useRef } from 'react';
import Select from 'react-select';
import { useBoundGeographyStore } from '../../../store/Geographies/useBoundGeographyStore';
import { useBoundNotificationFcm } from '../../../store/NotificationFcm/useBoundNotificationFcm';
import { useBoundNseStore } from '../../../store/useBoundNseStore';
import { formatterNumber } from '../../../helper/currencyFormatter';
import { LinearProgress } from '@mui/material';

export const SelectPanelistFcm = () => {

    const intervalProgress= useRef(1000);
    const { fetchTagsGeography,fetchGeography, tagsGeography,geographies } = useBoundGeographyStore( state => state );
    const { batchProgress,panelists,progressLoadPanelists, setterPanelist,searchPanelists,confirmLoadPanelists,errors, notification, loadings } = useBoundNotificationFcm( state => state );
    const { getRefenceNse,selectRefenceNse, types, nses, setter } = useBoundNseStore( state => state );
    const { panelists: pErros } = errors;
    const { errors:ePanelists  } = pErros;
    // almacenamos la busqueda de los panelista. 
    const handleSearchPanelist = (e) => {
        searchPanelists();
    }

    // almacenamos las usuarios en la notificaciones. 
    const uploadPanelists = (e) => {
        confirmLoadPanelists();
    }
    
    useEffect(() => {
        fetchTagsGeography();
        getRefenceNse();
    },[]);

    useEffect( () => {
        console.log('@notification', notification);
        if(["progress"].includes(notification.status) && parseInt(notification.step) == 3){ return; }
        if(notification.batchId != undefined){
            if(notification.status != 'progress' && batchProgress == undefined){ return; }
            if(intervalProgress.current != undefined){
                clearInterval(intervalProgress.current);
            }
            intervalProgress.current = setInterval(() => {
                if (batchProgress?.progress >= 0 && batchProgress?.progress <= 100) {
                    progressLoadPanelists();
                }
                if(batchProgress?.progress == 100){
                    clearInterval(intervalProgress.current);
                }
            }, 2000);
        }
        return () => clearInterval(intervalProgress.current);
    },[notification.batchId, batchProgress?.progress]);


    return (
        <>
            {
                loadings.panelists && ( 
                    <LinearProgress
                        variant="indeterminate"
                        sx={{
                            backgroundColor: "#552C75",
                            "& .MuiLinearProgress-bar": {backgroundColor: "#F3F3F3"}
                        }}
                    />
                )
            }
            {batchProgress?.progress < 100 &&
                <LinearProgress
                    variant="indeterminate"
                    sx={{
                        backgroundColor: "#552C75",
                        "& .MuiLinearProgress-bar": {backgroundColor: "#F3F3F3"}
                    }}
                />
            }
            <div style={{ padding:'1.15rem' }} >
                {
                    ePanelists?.global && (
                        <div className="row">
                            <div className="alert alert-danger">{ ePanelists?.global[0] }</div>
                        </div>
                    )
                }
                <div className="row">
                    <div className="form-group col-md-6">
                        <label className="my-1 fw-bold">Género</label>
                        <Select
                            key={1}
                            isMulti={true}
                            isClearable
                            className="basic-multi-select"
                            options={ panelists.gender.values }
                            value = {  panelists.gender.selected }
                            onChange  = { (e) => {
                                setterPanelist( { gender:{ ...panelists.gender, selected: e }} )
                            }}
                        />
                        {
                            ePanelists?.gender && ( 
                                <label className="text text-danger">{ePanelists?.gender[0] }</label>
                            )
                        }
                    </div>
                    <div className="form-group col-md-6">
                        <label className="my-1 fw-bold">Zonas gegráficas</label>
                        <Select
                            key={2}
                            isClearable
                            className="basic-select"
                            options={ tagsGeography.values }
                            value = {  panelists.tagGeo }
                            onChange  = { (e) => {
                                setterPanelist({ tagGeo: e })
                                fetchGeography(e, true)
                            }}
                        />
                        {
                            ePanelists?.tagGeo && ( 
                                <label className="text text-danger">{ePanelists?.tagGeo[0] }</label>
                            )
                        }
                    </div>
                    <div className="form-group col-md-6">
                        <label className="my-1 fw-bold">Seleccionar { panelists.tagGeo?.label || '' }</label>
                        <Select
                            key={1}
                            isMulti={true}
                            isClearable
                            className="basic-multi-select"
                            options={ geographies.values }
                            value = {  panelists.geographies }
                            onChange  = { (e) => {
                                setterPanelist( { geographies:e })
                            }}
                        />
                        {
                            ePanelists?.geographies && ( 
                                <label className="text text-danger">{ePanelists?.geographies[0] }</label>
                            )
                        }
                    </div>
                    <div className="form-group col-md-6">
                        <label className="my-1 fw-bold">Tipos de niveles socio económico</label>
                        <Select
                            key={3}
                            isClearable
                            className="basic-select"
                            options={ types.values }
                            value = {  panelists.typeNse }
                            onChange  = { (e) => {
                                selectRefenceNse( e, true )
                                setterPanelist( { nse: null, typeNse:e });
                            }}
                        />
                        {
                            ePanelists?.typeNse && ( 
                                <label className="text text-danger">{ePanelists?.typeNse[0] }</label>
                            )
                        }
                    </div>
                    
                    {
                        
                        (panelists.typeNse != undefined && panelists.typeNse?.value !== 'sin_nse' ) && (
                            <div className="form-group col-md-6">
                            <label className="my-1 fw-bold">Seleccionar niveles socio económico</label>
                            <Select
                                key={1}
                                isMulti={true}
                                isClearable
                                className="basic-multi-select"
                                options={ nses.values }
                                value = {  panelists.nse }
                                onChange  = { (e) => {
                                    setter({ nses: { ...nses, selected: e }})
                                    setterPanelist( { nse:e })
                                }}
                            />
                            {
                            ePanelists?.nse && ( 
                                    <label className="text text-danger">{ePanelists?.nse[0] }</label>
                                )
                            }
                            </div>

                        )
                    }
                    <div className="col-md-6">
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label className="my-1 fw-bold">Edad mínima</label>
                                <input className="form-control" type="number" value={panelists.ages.min} onChange={ (e) => setterPanelist({ ages:{ min:e.target.value, max:panelists.ages.max }}) }/>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="my-1 fw-bold">Edad máxima</label>
                                <input className="form-control" type="number" value={panelists.ages.max} onChange={ (e) => setterPanelist({ ages:{ min:panelists.ages.min, max:e.target.value }}) }/>
                            </div>
                        </div>
                        {
                            ePanelists?.ages && ( 
                                    <label className="text text-danger">{ePanelists?.ages[0] }</label>
                            )
                        }
                    </div>
                    {
                        !loadings.panelists && (
                            <>
                                {
                                    (notification.status == "draft" && notification.issetCache) && ( 
                                        <div className="alert alert-info mt-5">
                                            Se encontraron { formatterNumber(notification.totalPanelists) } dispositivos para generar envíos.
                                        </div>
                                    )
                                }
                                
                                <div className="col-md-12 mt-3" style={{ display:'flex', justifyContent:'flex-end' , gap:20  }}>
                                    {
                                        batchProgress?.progress < 100 ? (
                                            <button className='btn btn-primary-oo' disabled>{ `${ batchProgress.progress }%` }</button>
                                        ):(
                                            <>                                            
                                                <button className='btn btn-primary-oo' onClick={ handleSearchPanelist }>
                                                    <i className="fas fa-search"></i>&nbsp;
                                                    Buscar
                                                </button>
                                                {
                                                    (notification.status == "draft" && notification.issetCache) && (
                                                        <button className='btn btn-primary-oo' onClick={ uploadPanelists }>Cargar panelistas</button>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    )
}