import { useEffect } from "react";
import { AuthUserContext } from "../context/AuthUserContext";
import React, { useState } from "react";
import { getAuthenticatedUser } from "../api/utils/auth";

/**
 * Provider que obtiene la data del usuario con sesión iniciada, esta data está disponible en cualquier componente renderizado
 * dentro de <App></App>.
 * @param children - children component.
 * @returns children component wrapped in AuthUserContext.Provider
 */

export const AuthUserProvider = ({ children }) => {
    const [authUser, setAuthUser] = useState("authUser" in localStorage ? JSON.parse(localStorage.getItem("authUser")) : undefined);
    const [refreshKey, setRefreshKey] = useState(0);
    const fetchAuthUser = async () => {
        //Obtenemos data de usuario autorizado/autenticado
        const response = await getAuthenticatedUser();
        //Si la respuesta está ok, guardamos la data en local storage.
        if (response.status === 200) {
            localStorage.setItem("authUser", JSON.stringify(response.data));
            setAuthUser(response.data);
        } else {
            localStorage.setItem("authUser", null);
            setAuthUser(null);
        }
    };

    const refreshAuthUser = () => {
        // Función para forzar la actualización
        setRefreshKey((prev) => prev + 1);
    };

    useEffect(() => {
        //Si existe en localStorage, no hacemos nada.
        // if ("authUser" in localStorage) { return; }
        fetchAuthUser();
    }, [refreshKey]);

    return (
        <AuthUserContext.Provider value={{ authUser, refreshAuthUser }}>
            {children}
        </AuthUserContext.Provider>
    );
};