import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { App } from '../../../App';
import { FcmCreateNotification } from './FcmCreateNotification';
import { SelectPanelistFcm } from './SelectPanelistFcm';
import { useBoundNotificationFcm } from '../../../store/NotificationFcm/useBoundNotificationFcm';
import { ConfirmNotificationData } from './ConfirmNotificationData';
import { SelectPanelistSurvey } from './SelectPanelistSurvey';



export const AccordionForm = ({id}) => {
    const { setter,fetchNotification,step, notification } = useBoundNotificationFcm( state => state);
    useEffect( () => {
        fetchNotification(id);
    },[]);
    
    if(notification.status === 'send'){
        return (
            <div style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
            }}>
                <label style={{
                backgroundColor: "#A5D6A7",
                padding:'5px 20px',
                borderRadius:8,
            }}>Acá se mostrará las métricas correspondiente a esta notificación. </label></div>
        );
    }else if(['draft', 'progress'].includes(notification.status) && parseInt(notification.step) <= 3){
        return (<Accordion notification={ notification } setter={ setter } step={ step } /> );
    }
    return (<div>Cargando...</div>)
}


const Accordion = ({notification, setter, step }) => {

    const changeStep = (number) => {
        if(notification.step >= number){
            setter({step: number});
        }
    }
    return (
        <div>
            <div style={styles.menuStep}>
            <button style={{ ...styles.itemMenu, backgroundColor: (notification.step >= 1 && step == 1) ? '#C8E6C9' : notification.step >= 1 ? 'white' : 'var(--bs-gray-200)' }} onClick={() => changeStep(1)}>
                <div>
                        Información de campaña
                </div>
            </button>
            <button style={{ ...styles.itemMenu, backgroundColor: (notification.step >= 2 && step == 2) ? '#C8E6C9' : notification.step >= 2 ? 'white' : 'var(--bs-gray-200)' }}  onClick={() => changeStep(2)}>
                <div>
                        Selección de usuario
                </div>
            </button>
                <button style={{ ...styles.itemMenu, backgroundColor: (notification.step >= 3 && step == 3) ? '#C8E6C9' : notification.step >= 3 ? 'white' : 'var(--bs-gray-200)' }}  onClick={() => changeStep(3)}>
                    <div>
                        Detalle del envio
                    </div>
                </button>
            </div>
            <div style={ styles.container }>
                { notification.step >= 1 && step == 1 && (<div className={ `animate__animated animate__fadeIn`} ><FcmCreateNotification/></div>) }
                { (notification.step >= 2 && step == 2) && ( 
                    <div className={ `animate__animated animate__fadeIn`}>
                        {
                            notification.type === 'campaign' ? (<SelectPanelistFcm />) :(<SelectPanelistSurvey/>)
                        }
                    </div>) 
                }
                { (notification.step >= 3 && step == 3) && ( <div className={ `animate__animated animate__fadeIn`}><ConfirmNotificationData /></div>) }
            </div>
        </div>
    )
}

const styles = {
    container:{
        marginTop:'20px',
        backgroundColor:'white',
        padding:'20px',
        
    },
    menuStep:{
        display:'flex',
        flexDirection:'row',
        gap:20,
        justifyContent:'space-between'
    },
    itemMenu:{
        flex:1,
        textAlign:'center',
        border:`solid var(--bs-gray-500) 1px`,
        padding:'10px 30px',
        borderRadius:'10px'
    },
};
if (document.getElementById("jsx-fcm-create-notification")) {
    const el = document.getElementById("jsx-fcm-create-notification");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <AccordionForm {...props} />
        </App>
    );
}
