import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { MaterialReactTable } from 'material-react-table';
import { App } from '../../../App';
import { useGetProjects } from '../../../hooks/useProject';
import { projectStatus, programmingType } from '../../../helper/projectTransform.js';
import { AuthUserContext } from '../../../context/AuthUserContext.jsx';
import { ProjectTableMenuRowActions } from './ProjectTableMenurRowActions.jsx';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

export const Projects = () => {
  const [mount, setMount] = useState(false);
  //Permisos de acceso a funcionalidades
  const { authUser } = useContext(AuthUserContext);
  const [permissions, setPermissions] = useState({
    manager: false,
    marketing: false,
  });
  //Manipulación de material react table y consultas respectivas
  const debouncedRef = useRef(500);
  //Paginación, filtrado y ordenado manual.
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 15 });
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const previousValues = useRef({
    paginationPageIndex: pagination.pageIndex,
    paginationPageSize: pagination.pageSize,
    columnFilters,
    sorting
  });
  //Obtener proyectos
  const {
    projects,
    totalProjects,
    isLoading: isLoadingProject,
    isFetching: isFetchingProject,
    isError: isErrorProjects,
    error: errorProjects,
    refetch: refetchProjects
  } = useGetProjects(true, pagination, columnFilters, sorting);
  //Definición de columnas
  const columns = useMemo(
    () => [
      {
        accessorKey: 'status', //access nested data with dot notation
        header: 'Estado',
        filterVariant: "multi-select",
        filterSelectOptions: Object.entries(projectStatus).map(
          ([clave, valor]) => ({ text: valor, value: clave })
        ),
        Cell: ({ cell }) => {
          return <strong>{projectStatus[cell.getValue()]}</strong>
        },
      },
      {
        accessorKey: 'name',
        header: 'Titulo',
      },
      {
        accessorKey: 'nickname',
        header: 'Nickname',
      },
      {
        accessorKey: 'client',
        header: 'Cliente'
      },
      {
        accessorKey: 'loi',
        header: 'Tiempo promedio',
        filterVariant: "range",
      },
      {
        accessorKey: 'ir',
        header: 'Tasa incidencia',
        filterVariant: "range",
      },
      {
        accessorKey: 'sample',
        header: 'Muestra',
        filterVariant: "range",
      },
      {
        accessorKey: 'programming',
        header: 'Tipo programación',
        filterVariant: "multi-select",
        filterSelectOptions: Object.entries(programmingType).map(
          ([clave, valor]) => ({ text: valor, value: clave })
        ),
        Cell: ({ cell }) => {
          return programmingType[cell.getValue()]
        }
      }
    ],
    [],
  );

  useEffect(() => {
    setMount(true);
    if (mount) {
      // previusValues es para identificar por separado si el estado cambia respecto a su valor anterior
      // asi puedo identificar si el useEffect se aplica por cambios en pagination o en columnFilters
      // y de esta manera, diferenciar el tiempo del setTimeOut a mas o menos tiempo segun corresponda
      // mejorando la experiencia de usuario al hacer click en "retrodecer" o "siguiente" pagina
      let time =
        previousValues.current.paginationPageIndex !== pagination.pageIndex
          || previousValues.current.paginationPageSize !== pagination.pageSize
          ? 100
          : 1000;
      if (debouncedRef != undefined) {
        clearTimeout(debouncedRef.current);
      }
      previousValues.current = {
        paginationPageIndex: pagination.pageIndex,
        paginationPageSize: pagination.pageSize,
        columnFilters,
        sorting
      };
      debouncedRef.current = setTimeout(() => {
        refetchProjects(true, pagination, columnFilters)
      }, time);
    }
  }, [pagination.pageIndex, pagination.pageSize, columnFilters, sorting]);

  useEffect(() => {
    if (['super-admin'].includes(authUser.roles[0].name)) {
      setPermissions({ marketing: true, manager: true });
    }
    if (['marketing'].includes(authUser.roles[0].name)) {
      setPermissions({ marketing: true, manager: false });
    }
    if (['Project manager', 'commercial'].includes(authUser.roles[0].name)) {
      setPermissions({ marketing: false, manager: true });
    }
  }, [authUser])

  return (
    <div className="card">
      <div className="card-header">
        <div className="content-head-card">
          <label>Mostrar proyectos</label>
          <a className="btn btn-primary-oo" href="/proyecto/crear">Crear proyecto</a>
        </div>
      </div>
      <div className="card-body">
        <MaterialReactTable
          columns={columns}
          data={projects || []}
          initialState={{
            showColumnFilters: true,
            density: 'compact',
            columnVisibility: {
              loi: false,
              ir: false,
              nickname: false
            },
            columnPinning: {
              left: ['mrt-row-actions', 'status'],
            }
          }}
          manualPagination={true}
          manualFiltering={true}
          manualSorting={true}
          enableGlobalFilter={false}
          enableColumnFilters={true}
          isClearable={true}
          onPaginationChange={setPagination}
          onColumnFiltersChange={setColumnFilters}
          onSortingChange={setSorting}
          rowCount={totalProjects || 0}
          state={{
            isLoading: isLoadingProject || isFetchingProject,
            pagination,
            columnFilters,
            sorting,
            showAlertBanner: isErrorProjects,
          }}
          muiFilterTextFieldProps={{
            placeholder: 'Buscar...',
          }}
          muiTablePaperProps={{
            //customize paper styles
            sx: {
              boxShadow: 'none !important',
              border: 'none'
            },
          }}
          positionToolbarAlertBanner={'top'}
          muiToolbarAlertBannerProps={{
            color: 'error',
            severity: 'error',
            children: errorProjects?.data?.message || 'Ha ocurrido un error inesperado, intente nuevamente o comuniquese con soporte.'
          }}
          enableRowActions={true}
          positionActionsColumn={'first'}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "Acciones",
            },
          }}
          renderRowActions={({ row }) => (
            <ProjectTableMenuRowActions
              permissions={permissions}
              project={row.original}
            />
          )}
          localization={MRT_Localization_ES}
        />
      </div>
    </div>
  );

}

if (document.getElementById('jsx-projects')) {
  const el = document.getElementById("jsx-projects");
  const Index = ReactDOM.createRoot(el);
  const props = Object.assign({}, el.dataset);
  Index.render(
    <App>
      <Projects {...props} />
    </App>
  )
}