import { MaterialReactTable } from "material-react-table"
import { useContext, useEffect, useMemo } from "react"
import { useProjectStore } from "../../../../store/Project/useProjectStore"
import { AuthUserContext } from "../../../../context/AuthUserContext";

export const TrackerTable = ({ id }) => {
    
    // {['marketing','super-admin'].includes(authUser.roles[0].name)
    const { getTrackerInformation,trackerInformation, loading } = useProjectStore( state => state);
    const columns = useMemo(() => [ 
        {
            accessorKey: 'platform',
            header:'Medio'
        },{
            accessorKey:'completed',
            header:'Completas'
        },
        {
            accessorKey:'filtered',
            header:'Filtradas',
        },
        {
            accessorKey:'parcial',
            header:'Parciales',
        },{
            header:'T. Respuesta',
            Cell:({ row }) => {
                let _calc = ((row.original.parcial + row.original.completed + row.original.filtered)/row.original.totalBase)*100;
                _calc = Number(_calc.toFixed(2));
                return `${ _calc }%`
            }
        },
],[])

useEffect( () => {
    getTrackerInformation(id);
},[]);

if(loading){
    return (<div><label htmlFor="">Cargando información</label></div>)
}

    return (
            trackerInformation.length > 0 && ( 
                <div className="col-md-12 mt-4">
                    <div className='card card-header'>
                        <h5>Tracker información</h5>
                    </div>
                    <div className="card card-body">
                        <MaterialReactTable
                            columns={columns} 
                            data={trackerInformation} 
                            initialState={{ density: 'compact' }}
                            muiTablePaperProps={{
                                sx: {
                                    boxShadow: 'none !important',
                                    border: 'none'
                                },
                            }}
                            enableColumnActions={false}
                            enableColumnFilters={true}
                            enablePagination={false}
                            enableSorting={true}
                            enableBottomToolbar={false}
                            enableTopToolbar={true}
                            muiTableBodyRowProps={{ hover: true }}
                        />
                    </div>
                </div>
            )        
    )
}