import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInkIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import { Icon } from '@mui/material';

export const RrssListItem = ({ href, icon }) => {
    return (
        <a href={href} target="_blank" style={{ cursor: 'pointer' }}>
            {icon}
        </a>
    )
}

export const RrssIcon = ({ rrss }) => {
    const icons = {
        instagram: <InstagramIcon fontSize="18px" />,
        tiktok: <i className="fa-brands fa-tiktok" style={{ fontSize: '18px' }}></i>,
        facebook: <FacebookIcon fontSize="18px" />,
        x: <XIcon fontSize="18px" />,
        linkedin: <LinkedInkIcon fontSize="18px" />
    };
    return <Icon style={{ height: 'auto', margin: '3px' }} key={rrss + Math.random()}>{icons[rrss]}</Icon>;
}