import { Alert, Box, Button, CircularProgress, IconButton, ListItemIcon, Stack } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import { useExportPanelists } from "../../../../store/PanelistsInsight/usePanelistsStore";

export const ExportAlertStack = () => {
    const {
        exportStatus,
        resetExportStatus,
        closeExportStatusAlert,
        fetchDownloadExportedFile,
    } = useExportPanelists((state) => state);

    const selectAlerticon = (severity) => {
        const icons = {
            info: <CircularProgress size={20} />,
            success: <CheckIcon />,
            error: <ErrorOutlineIcon />,
            warning: <WarningAmberIcon />,
        };
        return icons[severity];
    }

    const handleDownloadExportedFile = async (type) => {
        const filenames = {
            panelist: exportStatus?.panelist?.filename,
            ranking: exportStatus?.ranking?.filename,
            campaign: exportStatus?.campaign?.filename,
            potential: exportStatus?.potential?.filename,
        };
        const downloadFilenames = {
            panelist: exportStatus?.panelist?.downloadFilename ?? 'panelists',
            ranking: exportStatus?.ranking?.downloadFilename ?? 'ranking-panelists',
            campaign: exportStatus?.campaign?.downloadFilename ?? 'campaign',
            potential: exportStatus?.potential?.downloadFilename ?? 'potential',
        };
        fetchDownloadExportedFile(filenames[type], type, downloadFilenames[type])
            .then(() => resetExportStatus(type));
    }

    return (
        <Stack sx={{ display: 'flex', gap: 1 }}>
            {exportStatus.panelist.status && exportStatus.panelist.message.length > 0 && (
                <Alert
                    key={"panelist-alert"}
                    variant="standard"
                    icon={selectAlerticon(exportStatus.panelist.severity)}
                    severity={exportStatus.panelist.severity}
                    {
                    ...(['error', 'warning'].includes(exportStatus.panelist.severity)
                        && { onClose: () => resetExportStatus('panelist') })
                    }
                    {
                    ...(['info'].includes(exportStatus.panelist.severity)
                        && { onClose: () => closeExportStatusAlert('panelist') })
                    }
                    {
                    ...(['success'].includes(exportStatus.panelist.severity)
                        && {
                        action: (
                            <>
                                <Button
                                    color="inherit"
                                    size="small"
                                    onClick={() => handleDownloadExportedFile('panelist')}
                                >
                                    DESCARGAR
                                </Button>
                                <IconButton size={'small'} onClick={() => closeExportStatusAlert('panelist')}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </>
                        )
                    })
                    }
                >
                    {exportStatus.panelist.message.map((msg, i) => {
                        return <span key={i}>{msg}</span>
                    })}
                </Alert>
            )}
            {exportStatus.ranking.status && exportStatus.ranking.message.length > 0 && (
                <Alert
                    key={"ranking-alert"}
                    variant="standard"
                    icon={selectAlerticon(exportStatus.ranking.severity)}
                    severity={exportStatus.ranking.severity}
                    {
                    ...(['error', 'warning'].includes(exportStatus.ranking.severity)
                        && { onClose: () => resetExportStatus('ranking') })
                    }
                    {
                    ...(['info'].includes(exportStatus.panelist.severity)
                        && { onClose: () => closeExportStatusAlert('ranking') })
                    }
                    {
                    ...(['success'].includes(exportStatus.ranking.severity)
                        && {
                        action: (
                            <Box>
                                <Button
                                    color="inherit"
                                    size="small"
                                    onClick={() => handleDownloadExportedFile('ranking')}
                                >
                                    DESCARGAR
                                </Button>
                                <IconButton size="small" onClick={() => closeExportStatusAlert('ranking')}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        )
                    })
                    }
                >
                    {exportStatus.ranking.message.map((msg, i) => {
                        return <span key={i}>{msg}</span>
                    })}
                </Alert>
            )}
            {exportStatus.campaign.status && exportStatus.campaign.message.length > 0 && (
                <Alert
                    key={"campaign-alert"}
                    variant="standard"
                    icon={selectAlerticon(exportStatus.campaign.severity)}
                    severity={exportStatus.campaign.severity}
                    {
                    ...(['error', 'warning'].includes(exportStatus.campaign.severity)
                        && { onClose: () => resetExportStatus('campaign') })
                    }
                    {
                    ...(['info'].includes(exportStatus.panelist.severity)
                        && { onClose: () => closeExportStatusAlert('campaign') })
                    }
                    {
                    ...(['success'].includes(exportStatus.campaign.severity)
                        && {
                        action: (
                            <>
                                <Button
                                    color="inherit"
                                    size="small"
                                    onClick={() => handleDownloadExportedFile('campaign')}
                                >
                                    DESCARGAR
                                </Button>
                                <IconButton size={'small'} onClick={() => closeExportStatusAlert('campaign')}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </>
                        )
                    })
                    }
                >
                    {exportStatus.campaign.message.map((msg, i) => {
                        return <span key={i}>{msg}</span>
                    })}
                </Alert>
            )}
            {exportStatus.potential.status && exportStatus.potential.message.length > 0 && (
                <Alert
                    key={"potential-alert"}
                    variant="standard"
                    icon={selectAlerticon(exportStatus.potential.severity)}
                    severity={exportStatus.potential.severity}
                    {
                    ...(['error', 'warning'].includes(exportStatus.potential.severity)
                        && { onClose: () => resetExportStatus('potential') })
                    }
                    {
                    ...(['info'].includes(exportStatus.panelist.severity)
                        && { onClose: () => closeExportStatusAlert('potential') })
                    }
                    {
                    ...(['success'].includes(exportStatus.potential.severity)
                        && {
                        action: (
                            <>
                                <Button
                                    color="inherit"
                                    size="small"
                                    onClick={() => handleDownloadExportedFile('potential')}
                                >
                                    DESCARGAR
                                </Button>
                                <IconButton size={'small'} onClick={() => closeExportStatusAlert('potential')}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </>
                        )
                    })
                    }
                >
                    {exportStatus.potential.message.map((msg, i) => {
                        return <span key={i}>{msg}</span>
                    })}
                </Alert>
            )}
        </Stack>
    )
}