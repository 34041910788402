import { Button, Link, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

export function ProjectTableMenuRowActions({ permissions, project }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const menuRowItems = [
        <MenuItem
            key={'project-detail-' + project.id}
            component="a"
            href={`/proyecto/${project.id}/detalle`}
            onClick={handleClose}
        >
            <ListItemIcon sx={{ margin: 0, padding: 0 }}><i className="fa fa-solid fa-eye"></i></ListItemIcon>
            Ver detalle
        </MenuItem>,
        <MenuItem
            key={'project-dashboard-' + project.id}
            component="a"
            href={`/proyecto/${project.id}/dashboard`}
            onClick={handleClose}
        >
            <ListItemIcon sx={{ margin: 0, padding: 0 }}><i className="fa fa-chart-area"></i></ListItemIcon>
            Dashboard
        </MenuItem>,
        <MenuItem
            key={'project-segmentation-' + project.id}
            component="a"
            href={`/proyecto/${project.id}/segmentar`}
            onClick={handleClose}
        >
            <ListItemIcon sx={{ margin: 0, padding: 0 }}><i className="fa fa-cubes"></i></ListItemIcon>
            Ver segmentos
        </MenuItem>,
        permissions.manager && (
            <MenuItem
                key={'project-edit-' + project.id}
                component="a"
                href={`/proyecto/${project.id}/editar`}
                onClick={handleClose}
            >
                <ListItemIcon sx={{ margin: 0, padding: 0 }}><i className="fa fa-edit"></i></ListItemIcon>
                Editar proyecto
            </MenuItem>
        ),
        permissions.manager && (
            <MenuItem
                key={'project-clone-' + project.id}
                component="a"
                href={`/proyecto/${project.id}/duplicar`}
                onClick={handleClose}
            >
                <ListItemIcon sx={{ margin: 0, padding: 0 }}><i className="fa fa-clone"></i></ListItemIcon>
                Duplicar proyecto
            </MenuItem>
        ),
        permissions.manager && (
            <MenuItem
                key={'project-panelists-' + project.id}
                component="a"
                href={`/proyecto/${project.id}/panelistas`}
                onClick={handleClose}
            >
                <ListItemIcon sx={{ margin: 0, padding: 0 }}><i className="fa fa-users"></i></ListItemIcon>
                Panelistas
            </MenuItem>
        ),
        permissions.manager && (
            <MenuItem
                key={'project-externalpanel-' + project.id}
                component="a"
                href={`/proyecto/${project.id}/panel-externo`}
                onClick={handleClose}
            >
                <ListItemIcon sx={{ margin: 0, padding: 0 }}><i className="fa fa-external-link-alt"></i></ListItemIcon>
                Integrar panel externo
            </MenuItem>
        ),
        permissions.manager && (
            <MenuItem
                key={'project-order-' + project.id}
                component="a"
                href={`/proyecto/ordenar`}
                onClick={handleClose}
            >
                <ListItemIcon sx={{ margin: 0, padding: 0 }}><i className="fa fa-up-down"></i></ListItemIcon>
                Ordenar proyectos
            </MenuItem>
        ),
        permissions.marketing && (
            <MenuItem
                key={'project-messages-' + project.id}
                component="a"
                href={`/proyecto/${project.id}/paginas`}
                onClick={handleClose}
            >
                <ListItemIcon sx={{ margin: 0, padding: 0 }}><i className="fa fa-message"></i></ListItemIcon>
                Configurar mensajes
            </MenuItem>
        ),
    ];
    return (
        <div>
            <Button
                id="project-row-menu-btn"
                color="inherit"
                sx={{ color: 'gray', borderColor: 'gray', marginTop: '2px', marginBottom: '2px' }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <i className="fa fa-solid fa-gear fs-5"></i>
            </Button>
            <Menu
                id="project-row-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'project-row-menu-btn',
                }}
            >
                {menuRowItems}
            </Menu>
        </div>
    )
}